import { makeVar, useReactiveVar } from '@apollo/client'

const errorVar = makeVar(null)

export const clearPasswordlessLoginError = () => {
  errorVar(null)
}

export const setPasswordlessLoginError = (error) => {
  errorVar(error)
}

export const usePasswordlessLoginError = () => useReactiveVar(errorVar)
