import React, { useCallback, useEffect } from 'react'
import Parse from 'parse'
import { money } from '@sellpy/commons'
import { useAsyncValue } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { region as getRegion } from '../market/src/common/region/region.js'
import ItemAmountElement from '../market/src/client/components/adyenSessionPayment/components/amountElements/ItemAmountElement.jsx'
import AdyenSessionPayment from '../market/src/client/components/adyenSessionPayment/AdyenSessionPayment.jsx'
import {
  PERMISSION_TYPE_ANALYTICS,
  PERMISSION_TYPE_DATA_POLICY,
  PERMISSION_TYPE_EMAIL_MARKETING,
  PERMISSION_TYPE_EXTERNAL_MARKETING
} from '../market/src/common/user/dataHandling.js'
import {
  analyticsSetUserEmail,
  analyticsSetUserPhone
} from '../market/src/common/analytics/actions.js'
import { CHECKOUT_TYPES } from '../market/src/client/checkout/analytics.js'
import { becomeUser, useCurrentParseUser } from '../market/src/common/user/currentUser.js'

/**
 * @deprecated We are no longer sending auth token through the window object.
 * But old app version might still use it so this hook still need to be here for the time being.
 * @returns user
 */
const useGetUserFromWindow = () => {
  const dispatch = useDispatch()
  const sessionToken = window.sessionToken

  // We need to use the selector here to re-render the component when the user log in
  const user = useCurrentParseUser()

  useEffect(() => {
    const loginUser = async () => {
      await becomeUser(sessionToken)
    }
    !user && sessionToken && loginUser()
  }, [sessionToken, dispatch, user])

  return user
}

const CheckoutItemPaymentsV1 = ({ paymentData }) => {
  const asyncUser = useAsyncValue()
  const reduxUser = useGetUserFromWindow()

  const userDataUsageConsent = useSelector((state) => state.user.dataUsageConsent)

  /**
   * To support the deprecated way of getting user from window object we must check if the user is
   * either fetched async (react-router-dom) or set in the redux state ([useGetUserFromWindow](../app-payments/PaymentProviders.jsx) hook)
   *
   */
  const user = asyncUser || reduxUser
  const isElibibleForReverseCharge = user?.get('businessBuyerConfig')?.type === 'warehouseSupport'

  const region = getRegion()

  const paymentSubmit = useCallback(
    ({ creditAmount, adyenPaymentData, dataUsageConsent, paymentId }) => {
      if (
        userDataUsageConsent.get(PERMISSION_TYPE_ANALYTICS) &&
        userDataUsageConsent.get(PERMISSION_TYPE_EXTERNAL_MARKETING)
      ) {
        analyticsSetUserPhone({ phone: paymentData.phone })
        analyticsSetUserEmail({ email: paymentData.email })
      }

      return Parse.Cloud.run('commenceSessionCheckoutItem', {
        paymentId,
        reservationIds: paymentData.reservationIds,
        address: paymentData.address,
        amount: money.toBaseUnit(paymentData.totalAmount),
        creditAmount,
        email: paymentData.email,
        deliveries: paymentData.deliveries,
        promoCodeString: paymentData?.validPromoCode?.code,
        version: 1,
        region: region,
        adyenPaymentData,
        origin: paymentData.origin,
        containerOrder: paymentData.containerOrder,
        dataUsageConsent: {
          ...paymentData.dataUsageConsent,
          ...dataUsageConsent
        },
        chargeAdFeeForSellerReturn: true
      })
    },
    [paymentData, region]
  )

  const paymentStart = useCallback(
    ({ creditAmount }) => {
      return Parse.Cloud.run('createSessionCheckoutItem', {
        country: paymentData.country,
        containerOrder: paymentData.containerOrder,
        reservationIds: paymentData.reservationIds,
        address: paymentData.address,
        amount: money.toBaseUnit(paymentData.totalAmount),
        creditAmount,
        email: paymentData.email,
        deliveries: paymentData.deliveries,
        promoCodeString: paymentData?.validPromoCode?.code,
        version: 1,
        region: region,
        origin: paymentData.origin,
        returnUrl: paymentData.returnUrl,
        chargeAdFeeForSellerReturn: true
      })
    },
    [paymentData, region]
  )

  return (
    <div>
      {user && (
        <AdyenSessionPayment
          paymentSubmit={paymentSubmit}
          paymentStart={paymentStart}
          totalAmount={paymentData.totalAmount}
          amountElement={
            <ItemAmountElement
              totalItemAmountWithoutPromoCode={paymentData.totalItemAmountWithoutPromoCode}
              totalItemAmountPromoCodeDiscount={paymentData.totalItemAmountPromoCodeDiscount}
              freightPrice={money.toSubUnit(
                paymentData?.totalFreightAmount || paymentData?.deliveries?.regular?.price
              )}
              isElibibleForReverseCharge={isElibibleForReverseCharge}
            />
          }
          userPermissions={[
            { type: PERMISSION_TYPE_DATA_POLICY, required: true },
            { type: PERMISSION_TYPE_EMAIL_MARKETING, required: false }
          ]}
          appPaymentReservationIds={paymentData.reservationIds}
          checkoutType={CHECKOUT_TYPES.CART}
        />
      )}
    </div>
  )
}

export default CheckoutItemPaymentsV1
