import { Icon, ICONS, P, Span } from '@sellpy/design-system-react-web'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { makeVar, useReactiveVar } from '@apollo/client'
import { mediaQueries } from '../uiComponents/mediaQueries'

const BannerText = styled(P)`
  margin: 2px 0px;
  text-align: center;
  ${mediaQueries.phone} {
    margin: 2px 4px;
  }
`

const Wrapper = styled.div`
  transition: height 0.3s;
  display: flex;
  width: 100%;
  background-color: ${({ theme, tint }) => theme.color[tint].shade10};
  padding: 8px 0;
  z-index: 200;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 40px;
`

const PromoText = styled(P)`
  margin: 0;
  padding: 0 4px;
  text-align: center;
`

const ShowMore = styled(Span)`
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`

const CloseButton = styled.button`
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.color.black.default};
`

const AdditionalSentence = styled.span`
  padding-left: 4px;
`

export const savedPromoBannerHeight = makeVar(0)
export const userDismissedVar = makeVar(false)

const PromoBanner = ({ firstRowContent, secondRowContent, tint = 'blue' }) => {
  const [showMore, setShowMore] = useState(false)
  const userDismissed = useReactiveVar(userDismissedVar)
  const { t } = useTranslation('common')
  const ref = useRef()
  const currentlySavedHeight = useReactiveVar(savedPromoBannerHeight)

  useEffect(() => {
    const height = (ref.current && ref.current.scrollHeight) || 0
    if (currentlySavedHeight !== height) savedPromoBannerHeight(height)

    return () => currentlySavedHeight > 0 && savedPromoBannerHeight(0)
  }, [currentlySavedHeight])

  const handleClick = () => userDismissedVar(true)

  if (userDismissed) return null

  return (
    <Wrapper ref={ref} tint={tint}>
      <div style={{ flexDirection: 'column', alignItems: 'center' }}>
        <InnerWrapper>
          <PromoText inline design='body3'>
            {firstRowContent}
            {secondRowContent && (
              <>
                {' '}
                <ShowMore
                  design='body3'
                  inline
                  onClick={() => {
                    setShowMore(!showMore)
                  }}
                >
                  {showMore ? t('promoBanner.lessInfo') : t('promoBanner.moreInfo')}
                </ShowMore>
              </>
            )}
          </PromoText>
          {showMore && (
            <BannerText design='body3'>
              <AdditionalSentence>{secondRowContent}</AdditionalSentence>
            </BannerText>
          )}
        </InnerWrapper>
      </div>
      <CloseButton aria-label='Close banner' role='button' onClick={handleClick}>
        <Icon name={ICONS.CLOSE} style={{ fontSize: '14px' }} />
      </CloseButton>
    </Wrapper>
  )
}

export default PromoBanner
