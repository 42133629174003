export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'

export const showToast = (options = {}) => (dispatch) => {
  const now = Date.now()
  dispatch({
    payload: {
      key: now,
      options
    },
    type: SHOW_TOAST
  })
  setTimeout(() => dispatch(hideToast(now)), 7000)
}

const hideToast = (key) => {
  return {
    payload: key,
    type: HIDE_TOAST
  }
}

export const SET_NUMBER_OF_P2P_HITS_SHOWN = 'SET_NUMBER_OF_P2P_HITS_SHOWN'
export const setNumberOfP2pHitsShown = (numberOfHitsShown) => ({
  type: SET_NUMBER_OF_P2P_HITS_SHOWN,
  numberOfHitsShown: numberOfHitsShown
})

export const NEW_P2P_SEARCH = 'NEW_P2P_SEARCH'
export const newP2pSearch = (numberOfHitsShown, lastSearchUrl) => ({
  type: NEW_P2P_SEARCH,
  numberOfHitsShown: numberOfHitsShown,
  lastSearchUrl: lastSearchUrl
})

export const SET_COOKIE_SETTINGS_MODAL_OPEN = 'SET_COOKIE_SETTINGS_MODAL_OPEN'
export const cookieSettingsModalOpen = (value) => ({
  type: SET_COOKIE_SETTINGS_MODAL_OPEN,
  value: value
})
