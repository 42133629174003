fragment ItemMetaData on Item {
  objectId
  itemStatus
  metadata
  metadata_de
  metadata_en
  metadata_nl
  metadata_da
  metadata_pl
  metadata_fi
  metadata_fr
  metadata_cs
  itemIO {
    bodyOutputOrder {
      ... on Element {
        value
      }
    }
    titleOutputOrder {
      ... on Element {
        value
      }
    }
  }
  images {
    ... on Element {
      value
    }
  }
  user {
    objectId
  }
  itemAbTestFraction
}
