import styled from 'styled-components'
import { mediaQueries } from '../uiComponents/mediaQueries'

export const QuickFilterContainer = styled.div`
  display: flex;
  overflow: scroll;
  gap: 8px;
  padding: 0 24px;
  ${mediaQueries.phone} {
    padding: 0 16px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const SearchGrid = styled.div`
  margin: 0 0 8px 0;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-row-gap: 8px;
  grid-template-areas:
    'bc bc bc bc bc bc bc bc bc bc bc bc'
    'id id id id id id id id id id id id'
    'sb sb sb sb sb sb sb sb sb sb sb sb'
    'rs rs rs rs rs rs rs rs rs rs rs rs'
    'pg pg pg pg pg pg pg pg pg pg pg pg'
    'cr cr cr cr cr cr cr cr cr cr cr cr'
    'pr pr pr pr pr pr pr pr pr pr pr pr'
    'pf pf pf pf pf pf pf pf pf pf pf pf';
  grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) auto minmax(0, auto);

  ${mediaQueries.mobile} {
    width: 100vw;
  }

  ${mediaQueries.largerThanTablet} {
    ${({ theme }) => theme.layout.contentPadding};
    max-width: calc(100% - 72px);
    margin: 16px 0;
    grid-column-gap: 16px;
    grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) auto auto;
    grid-template-areas:
      'lc lc lc bc bc bc bc bc bc bc bc bc'
      'lc lc lc hr hr hr hr hr hr hr hr hr'
      'lc lc lc hf hf hf hf hf hf hf hf hf'
      'lc lc lc id id id id id id id id id'
      'lc lc lc sb sb sb sb sb sb sb sb sb'
      'lc lc lc rs rs rs rs rs rs rs rs rs'
      'lc lc lc pg pg pg pg pg pg pg pg pg'
      'lc lc lc cr cr cr cr cr cr cr cr cr'
      'lc lc lc pr pr pr pr pr pr pr pr pr'
      'lc lc lc pf pf pf pf pf pf pf pf pf';
  }
  ${mediaQueries.largeDesktop} {
    margin: 24px 0;
    grid-template-areas:
      'lc lc bc bc bc bc bc bc bc bc bc bc'
      'lc lc hr hr hr hr hr hr hr hr hr hr'
      'lc lc hf hf hf hf hf hf hf hf hf hf'
      'lc lc id id id id id id id id id id'
      'lc lc sb sb sb sb sb sb sb sb sb sb'
      'lc lc rs rs rs rs rs rs rs rs rs rs'
      'lc lc pg pg pg pg pg pg pg pg pg pg'
      'lc lc cr cr cr cr cr cr cr cr cr cr'
      'lc lc pr pr pr pr pr pr pr pr pr pr'
      'lc lc pf pf pf pf pf pf pf pf pf pf';
  }
  .item-tile-container {
    grid-column: span 6;
    // In Firefox, by default, flex items won’t shrink below their minimum content size
    // This means the minimum width of an item is set to the width of its content and it won’t shrink past that width.
    // This can result in layouts appearing uneven or wrapping too early.
    // By adding min-width: 0 to the item, it will resize at the correct ratio and still apply any overflow as desired.
    min-width: 0;
    ${mediaQueries.largerThanTablet} {
      grid-column: span 3;
    }
    ${mediaQueries.largeDesktop} {
      grid-column: span 2;
    }
  }
`

export const SearchBannerDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: sb;
`

export const PromotionalFooterDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: pf;
`

export const LeftColumnDiv = styled.div`
  grid-area: lc;
  margin-top: 8px;
`

export const HeaderDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: hr;
  display: flex;
  align-items: center;
`

export const BreadCrumbsDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  overflow-x: scroll;
  grid-area: bc;
  margin-right: 8px;
`

export const ResultsDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: rs;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  ${mediaQueries.largerThanTablet} {
    grid-template-columns: repeat(9, [col-start] 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  ${mediaQueries.largeDesktop} {
    grid-template-columns: repeat(10, [col-start] 1fr);
  }
`

export const HorizontalFiltersDiv = styled.div`
  grid-area: hf;
`

export const StyledBorder = styled.hr`
  width: 100%;
  border-top: ${({ theme }) => theme.color.grey.shade7};
  margin: 0;
`

export const SearchActionsContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, calc(50% - 1px));
  border: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
  border-style: solid none;
  > :first-child {
    border: none;
    border-right: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
  }
`

export const RefinementsContainer = styled.div`
  display: flex;
  align-content: space-between;
  gap: 24px;
  margin-top: 4px;
`

export const IndicatorsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: id;
`

export const SearchSortIndicatorsDiv = styled.div`
  display: flex;
  align-items: center;

  ${mediaQueries.tablet} {
    justify-content: space-between;
    padding: 0 16px;
  }

  ${mediaQueries.phone} {
    padding: 0 8px;
  }
`

export const PaginationDiv = styled.div`
  ${mediaQueries.tablet} {
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: pg;
`

export const P2pResultsDiv = styled.div`
  margin-top: 32px;
  ${mediaQueries.tablet} {
    margin-top: 24px;
    ${({ theme }) => theme.layout.contentPadding};
  }
  grid-area: pr;
`

export const CLIPResultsDiv = styled.div`
  grid-area: cr;
`
