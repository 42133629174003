import { gql, useSuspenseQuery } from '@apollo/client'
import { getApolloClient } from '../../client/apollo/apolloClientSingleton'
import cache from '../../client/apollo/cache'

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      objectId
      username
      email
      address
      userAbTestFraction
      saleConfig
      businessBuyerConfig
      locale
      createdAt
    }
  }
`

export const getCurrentUser = async () => {
  const { data } = await getApolloClient().query({ query: CURRENT_USER })
  return data.currentUser
}
export const useCurrentUser = () => {
  const { data } = useSuspenseQuery(CURRENT_USER, {})
  return data.currentUser
}

export const refetchCurrentUser = () => getApolloClient().refetchQueries({ query: CURRENT_USER })

export const resetCurrentUserCache = () =>
  cache.updateQuery({ query: CURRENT_USER, broadcast: true }, () => ({ currentUser: null }))
