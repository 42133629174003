import { fromJS } from 'immutable'
import Parse from '../../client/lib/parse'
import { cacheEntities } from '../entities/actions'
import { runCloudCached } from '../lib/parseTools'

export const SAVED_CO2_AND_WATER_SAVINGS_SUCCESS = 'SAVED_CO2_AND_WATER_SAVINGS_SUCCESS'
export const FETCH_SALE_PROCESS_ESTIMATE_SUCCESS = 'FETCH_SALE_PROCESS_ESTIMATE_SUCCESS'
export const FETCH_OFFICICAL_PRICING_MODEL_SUCCESS = 'FETCH_OFFICICAL_PRICING_MODEL_SUCCESS'
export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE'

export const getSavedWaterAndCO2ForSale = ({ saleId }) => {
  return (dispatch) => {
    return Parse.Cloud.run('countSavedWaterAndCO2ForSale', { saleId }).then((savedWaterAndCO2) => {
      if (savedWaterAndCO2)
        dispatch({
          type: SAVED_CO2_AND_WATER_SAVINGS_SUCCESS,
          savedWaterAndCO2: fromJS({ [saleId]: savedWaterAndCO2 })
        })
    })
  }
}

export const getContainersBySaleId = (saleId) => {
  return (dispatch) =>
    Parse.Cloud.run('getContainersBySaleId', { saleId }).then((containers) =>
      dispatch(cacheEntities(containers))
    )
}

export const getOwnSales = () => async (dispatch) => {
  return runCloudCached((ownBags) => dispatch(cacheEntities(ownBags)))('getOwnSales')
}

export const getBagRatings = (bagIds) => async (dispatch) => {
  return runCloudCached((bagRatings) => dispatch(cacheEntities(bagRatings)))('getBagRatings', {
    bagIds
  })
}

export const getSale = (saleId) => async (dispatch) => {
  return runCloudCached((sale) => dispatch(cacheEntities(sale)))('getSale', { saleId })
}

export const fetchSaleProcessEstimation = (saleId) => async (dispatch) => {
  return runCloudCached((estimate) =>
    dispatch({ type: FETCH_SALE_PROCESS_ESTIMATE_SUCCESS, saleId, estimate })
  )('daysUntilProcessing', { saleId, region: process.env.REGION })
}

export const fetchOfficialPricingModel = (currency) => async (dispatch) => {
  return runCloudCached((pricingModel) =>
    dispatch({ type: FETCH_OFFICICAL_PRICING_MODEL_SUCCESS, pricingModel, currency })
  )('getOfficialPricingModel', { currency })
}

export const setReferralCode = (referralCode) => ({
  type: SET_REFERRAL_CODE,
  referralCode
})
