import { combineReducers } from 'redux'
import { Stack } from 'immutable'
import { getVisitedItems, persistVisitedItems } from '../localStorage'
import * as actions from './actions'

const lastVisitedItemIds = (state = Stack(getVisitedItems()), { type, itemId }) => {
  switch (type) {
    case actions.TRACK_ITEM_VISIT: {
      const existingIds = state.filter((existingId) => existingId !== itemId).take(19) // i.e max length of 20

      const visitedItems = existingIds.push(itemId)
      persistVisitedItems(visitedItems.toJS())
      return visitedItems
    }
    default:
      return state
  }
}

export default combineReducers({
  lastVisitedItemIds
})
