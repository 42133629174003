import { searchUrls } from '@sellpy/commons'
import { ICONS, Icon } from '@sellpy/design-system-react-web'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { analyticsSearch } from '../../../../../../common/analytics/actions.js'
import { region } from '../../../../../../common/region/region'
import { useResponsive } from '../../../../../hooks'
import {
  IconContainer,
  IconWithColor,
  RoundButton,
  SearchInputContainer,
  StyledInput
} from '../Styles.jsx'

const InputIconsDivider = () => {
  const theme = useTheme()

  return (
    <div
      style={{
        width: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          width: '1px',
          height: '24px',
          backgroundColor: `${theme.color.grey.shade6}`
        }}
      />
    </div>
  )
}

const SearchButtonWithLink = ({ query }) => (
  <Link
    style={{ display: 'flex' }}
    onMouseDown={(event) => {
      event.preventDefault()
      analyticsSearch({
        searchTerm: query,
        searchSource: 'text'
      })
    }}
    to={
      query
        ? searchUrls.stringifySearchStateV2(region())({
            query: query
          })
        : '/search'
    }
  >
    <RoundButton variant='text' size='small' centerIcon={<IconWithColor name={ICONS.SEARCH} />} />
  </Link>
)

export const SearchInput = forwardRef(({ isFocused, setIsFocused, query, autocomplete }, ref) => {
  const { isTablet } = useResponsive()
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation(['search', 'common'])

  const autocompleteInputProps = autocomplete.getInputProps({})

  const shouldShowClearButton = Boolean(query)
  const shouldShowRightSearchButton = Boolean(isTablet && isFocused && query)

  return (
    <SearchInputContainer isFocused={isFocused}>
      {isTablet && isFocused ? (
        <RoundButton
          onClick={() => {
            autocomplete.setIsOpen(false)
            autocomplete.setQuery('')
          }}
          variant='text'
          size='small'
          style={{ height: '40px', width: '40px' }}
          centerIcon={
            <Icon
              name={ICONS.ARROW_LEFT}
              style={{ fontSize: '24px', color: theme.color.grey.shade3 }}
            />
          }
        />
      ) : isTablet ? (
        <SearchButtonWithLink query={query} />
      ) : (
        <IconContainer borderIncreaseRight={isFocused}>
          <IconWithColor name={ICONS.SEARCH} />
        </IconContainer>
      )}
      <StyledInput
        {...autocompleteInputProps}
        removeSpace={isTablet}
        isFocused={isFocused}
        autoFocus={isFocused}
        ref={ref}
        placeholder={t('common:header.searchPlaceholder')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            analyticsSearch({
              searchTerm: query,
              searchSource: 'text'
            })
            autocomplete.setIsOpen(false)
            navigate(
              query
                ? searchUrls.stringifySearchStateV2(region())({
                    query
                  })
                : '/search'
            )
          }
        }}
        onFocus={(event) => {
          setIsFocused(true)
          // eslint-disable-next-line no-unused-expressions
          autocompleteInputProps?.onFocus?.(event)
        }}
        onBlur={(event) => {
          setIsFocused(false)
          autocomplete.setIsOpen(false)
          // eslint-disable-next-line no-unused-expressions
          autocompleteInputProps?.onBlur?.(event)
        }}
      />
      {shouldShowClearButton && (
        <RoundButton
          onMouseDown={(event) => {
            autocomplete.setQuery('')
            setIsFocused(true)
            autocomplete.setIsOpen(true)
            // eslint-disable-next-line no-unused-expressions
            ref?.current?.focus()
            autocomplete.refresh()
            event.preventDefault()
          }}
          isFocused={isFocused}
          increaseBorderFromRight={isFocused}
          variant='text'
          size='small'
          centerIcon={
            <Icon
              name={ICONS.CLOSE_CIRCLE}
              style={{ fontSize: '24px', color: theme.color.grey.shade3 }}
            />
          }
        />
      )}
      {shouldShowRightSearchButton && (
        <>
          {shouldShowClearButton && shouldShowRightSearchButton && <InputIconsDivider />}
          <SearchButtonWithLink query={query} />
        </>
      )}
    </SearchInputContainer>
  )
})

SearchInput.displayName = 'SearchInput'
