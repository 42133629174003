import React, { useCallback, useEffect } from 'react'
import Parse from 'parse'
import { useDispatch } from 'react-redux'
import { marketplace } from '@sellpy/commons'
import { useAsyncValue } from 'react-router-dom'
import AdyenSessionPayment from '../market/src/client/components/adyenSessionPayment/AdyenSessionPayment.jsx'
import ContainerOrderCheckoutAmountElement from '../market/src/client/orderBag/components/ContainerOrderCheckoutAmountElement.jsx'
import { getPromoCode } from '../market/src/common/paymentConfirmation/actions.js'
import { region as getRegion } from '../market/src/common/region/region.js'
import {
  PERMISSION_TYPE_DATA_POLICY,
  PERMISSION_TYPE_EMAIL_MARKETING
} from '../market/src/common/user/dataHandling.js'
import { CHECKOUT_TYPES } from '../market/src/client/checkout/analytics.js'

const ContainerOrderPayments = ({ paymentData }) => {
  const dispatch = useDispatch()
  const orderedFrom = 'app'
  const user = useAsyncValue()
  const region = getRegion()

  useEffect(() => {
    paymentData.validPromoCodeId &&
      dispatch(getPromoCode({ promoCodeId: paymentData.validPromoCodeId }))
  }, [paymentData.validPromoCodeId, dispatch])

  const paymentSubmit = useCallback(
    async (data) => {
      return Parse.Cloud.run('commenceSessionCheckoutContainerOrders', {
        ...data,
        ...paymentData,
        region,
        orderedFrom,
        useNewPricing: Boolean(paymentData.containerPrice),
        dataUsageConsent: {
          ...paymentData.dataUsageConsent,
          ...data.dataUsageConsent
        }
      })
    },
    [paymentData, region]
  )

  const paymentStart = useCallback(
    async (data) => {
      return Parse.Cloud.run('createSessionCheckoutContainerOrders', {
        ...data,
        ...paymentData,
        region,
        orderedFrom,
        useNewPricing: Boolean(paymentData.containerPrice)
      })
    },
    [paymentData, region]
  )

  return (
    <div>
      {user && (
        <AdyenSessionPayment
          paymentSubmit={paymentSubmit}
          paymentStart={paymentStart}
          totalAmount={paymentData.totalAmount}
          amountElement={
            <ContainerOrderCheckoutAmountElement
              nrOfBags={paymentData.nrOfBags}
              promoCodeId={paymentData.validPromoCodeId}
              containerPrice={paymentData.containerPrice || oldContainerPrice(region)}
            />
          }
          userPermissions={[
            { type: PERMISSION_TYPE_DATA_POLICY, required: true },
            { type: PERMISSION_TYPE_EMAIL_MARKETING, required: false }
          ]}
          checkoutType={CHECKOUT_TYPES.CONTAINER}
        />
      )}
    </div>
  )
}

const oldContainerPrice = (region) =>
  ({
    DE: { currency: marketplace.CURRENCY[region], amount: 195 },
    SE: { currency: marketplace.CURRENCY[region], amount: 1900 },
    AT: { currency: marketplace.CURRENCY[region], amount: 195 },
    NL: { currency: marketplace.CURRENCY[region], amount: 195 },
    DK: { currency: marketplace.CURRENCY[region], amount: 1500 },
    FI: { currency: marketplace.CURRENCY[region], amount: 195 },
    EU: null,
    PL: null,
    FR: { currency: marketplace.CURRENCY[region], amount: 195 },
    BE: { currency: marketplace.CURRENCY[region], amount: 195 }
  }[region])

export default ContainerOrderPayments
