import { imageTools, filesTools } from '@sellpy/commons'

export default {
  environment: 'prod',
  environmentColor: 'white',
  staticAssets: filesTools.getFileUrl('https://sellpy-public-assets.s3.amazonaws.com/webApp/'),
  staticImageAssets: imageTools.optimizeImage(
    'https://sellpy-public-assets.s3.amazonaws.com/webApp/'
  ),
  adyen: {
    clientKey: 'live_HVLNPU4ZXBAUBAG6NXY5MXMTY4DVGALN',
    environment: 'live'
  },
  algolia: {
    applicationIdSV: 'M6WNFR0LVI',
    applicationIdNL: 'NGFIBXY77H',
    applicationIdFR: 'G04G8KCJV4',
    applicationIdFI: '50LBQU2I4A',
    applicationIdDA: '3LXSU2DN7T',
    applicationIdDE: 'VXBNWNP8XQ',
    applicationIdPL: '26ZHDPXASS',
    applicationIdCS: 'XN9P7O0Z19',
    applicationIdEN: 'F1JX0VYE3G',
    applicationIdNonItem: 'I490MW2ATB',
    searchKeySV: '313e09c3b00b6e2da5dbe382cd1c8f4b',
    searchKeyNL: 'c98fc1c21abf20589ad1a6417343f3e2',
    searchKeyFR: 'c666d7511b996314df31ad5bc97e4fc4',
    searchKeyFI: '4e696a94df3469d5356870d63c118c87',
    searchKeyDA: '380077912d5cdc2bebf67d4b4ad10a30',
    searchKeyDE: '7a496b7de36cf05a3616039c8040a976',
    searchKeyPL: '8fd1a7966cdfc10037e730c678ed2e21',
    searchKeyCS: 'e7692b688c35cd122629090293e55a98',
    searchKeyEN: '853170d0bcb8edda1d8ce97c5ded2b24',
    searchKeyNonItem: '6c6f004c2647e8e5af953e891d401b9b',
    marketItemDeIndexSaleStartDescending: 'prod_marketItem_de_saleStartedAt_desc',
    marketItemDeIndexRelevance: 'prod_marketItem_de_relevance',
    marketItemDeIndexPriceAscending: 'prod_marketItem_de_price_asc',
    marketItemDeIndexPriceDescending: 'prod_marketItem_de_price_desc_virtual',
    marketItemSeIndexSaleStartDescending: 'prod_marketItem_se_saleStartedAt_desc',
    marketItemSeIndexRelevance: 'prod_marketItem_se_relevance',
    marketItemSeIndexPriceAscending: 'prod_marketItem_se_price_asc',
    marketItemSeIndexPriceDescending: 'prod_marketItem_se_price_desc_virtual',
    marketItemEnIndexSaleStartDescending: 'prod_marketItem_en_saleStartedAt_desc',
    marketItemEnIndexRelevance: 'prod_marketItem_en_relevance',
    marketItemEnIndexPriceAscending: 'prod_marketItem_en_price_asc',
    marketItemEnIndexPriceDescending: 'prod_marketItem_en_price_desc_virtual',
    marketItemNlIndexSaleStartDescending: 'prod_marketItem_nl_saleStartedAt_desc',
    marketItemNlIndexRelevance: 'prod_marketItem_nl_relevance',
    marketItemNlIndexPriceAscending: 'prod_marketItem_nl_price_asc',
    marketItemNlIndexPriceDescending: 'prod_marketItem_nl_price_desc_virtual',
    marketItemDaIndexSaleStartDescending: 'prod_marketItem_da_saleStartedAt_desc',
    marketItemDaIndexRelevance: 'prod_marketItem_da_relevance',
    marketItemDaIndexPriceAscending: 'prod_marketItem_da_price_asc',
    marketItemDaIndexPriceDescending: 'prod_marketItem_da_price_desc_virtual',
    marketItemPlIndexSaleStartDescending: 'prod_marketItem_pl_saleStartedAt_desc',
    marketItemPlIndexRelevance: 'prod_marketItem_pl_relevance',
    marketItemPlIndexPriceAscending: 'prod_marketItem_pl_price_asc',
    marketItemPlIndexPriceDescending: 'prod_marketItem_pl_price_desc_virtual',
    marketItemFiIndexSaleStartDescending: 'prod_marketItem_fi_saleStartedAt_desc',
    marketItemFiIndexRelevance: 'prod_marketItem_fi_relevance',
    marketItemFiIndexPriceAscending: 'prod_marketItem_fi_price_asc',
    marketItemFiIndexPriceDescending: 'prod_marketItem_fi_price_desc_virtual',
    marketItemFrIndexSaleStartDescending: 'prod_marketItem_fr_saleStartedAt_desc',
    marketItemFrIndexRelevance: 'prod_marketItem_fr_relevance',
    marketItemFrIndexPriceAscending: 'prod_marketItem_fr_price_asc',
    marketItemFrIndexPriceDescending: 'prod_marketItem_fr_price_desc_virtual',
    marketItemCsIndexSaleStartDescending: 'prod_marketItem_cs_saleStartedAt_desc',
    marketItemCsIndexRelevance: 'prod_marketItem_cs_relevance',
    marketItemCsIndexPriceAscending: 'prod_marketItem_cs_price_asc',
    marketItemCsIndexPriceDescending: 'prod_marketItem_cs_price_desc_virtual',
    querySuggestionsSV: 'prod_querySuggestions2_sv',
    querySuggestionsDE: 'prod_querySuggestions2_de',
    querySuggestionsEN: 'prod_querySuggestions2_en',
    querySuggestionsDA: 'prod_querySuggestions2_da',
    querySuggestionsPL: 'prod_querySuggestions2_pl',
    querySuggestionsFI: 'prod_querySuggestions2_fi',
    querySuggestionsNL: 'prod_querySuggestions2_nl',
    querySuggestionsFR: 'prod_querySuggestions2_fr',
    querySuggestionsCS: 'prod_querySuggestions2_cs',
    structuredSearchQuerySuggestionsSV: 'prod_structuredSearchQuerySuggestions_sv',
    structuredSearchQuerySuggestionsDE: 'prod_structuredSearchQuerySuggestions_de',
    structuredSearchQuerySuggestionsEN: 'prod_structuredSearchQuerySuggestions_en',
    structuredSearchQuerySuggestionsDA: 'prod_structuredSearchQuerySuggestions_da',
    structuredSearchQuerySuggestionsPL: 'prod_structuredSearchQuerySuggestions_pl',
    structuredSearchQuerySuggestionsFI: 'prod_structuredSearchQuerySuggestions_fi',
    structuredSearchQuerySuggestionsNL: 'prod_structuredSearchQuerySuggestions_nl',
    structuredSearchQuerySuggestionsFR: 'prod_structuredSearchQuerySuggestions_fr',
    structuredSearchQuerySuggestionsCS: 'prod_structuredSearchQuerySuggestions_cs',
    itemIndexRestrictedKey:
      'M2ZiNmNlZjljMjY1ZDRkOWQ0OWE2NDBhZDRiZWU3Mzg5MzMxZjE3MmQzN2IzNjUxNDgzNmUxYTdjMjk0ZjE1YmZpbHRlcnM9aXRlbVN0YXR1cyUzQWJldGFsZCUyME9SJTIwaXRlbVN0YXR1cyUzQXNrJUMzJUE0bmt0JmF0dHJpYnV0ZXNUb1JldHJpZXZlPSU1QiUyMm1ldGFkYXRhJTIyJTJDJTIyaGVhZGxpbmUlMjIlMkMlMjJjdXJyZW50VmFsdWUlMjIlMkMlMjJpbWFnZXMlMjIlMkMlMjJjYXQxJTIyJTJDJTIyY2F0MiUyMiUyQyUyMmNhdDMlMjIlMkMlMjJjYXQ0JTIyJTJDJTIyaXRlbVN0YXR1cyUyMiUyQyUyMm9iamVjdElkJTIyJTJDJTIycGFpZEF0JTIyJTJDJTIydXBkYXRlZEF0JTIyJTVEJnJlc3RyaWN0SW5kaWNlcz1wcm9kX0l0ZW0=',
    brandIndex: 'prod_itemBrand',
    typeIndexSV: 'prod_itemType',
    typeIndexDE: 'prod_itemType_de',
    typeIndexEN: 'prod_itemType_en',
    typeIndexNL: 'prod_itemType_nl',
    typeIndexDA: 'prod_itemType_da',
    typeIndexPL: 'prod_itemType_pl',
    typeIndexFI: 'prod_itemType_fi',
    typeIndexFR: 'prod_itemType_fr',
    typeIndexCS: 'prod_itemType_cs',
    colorIndex: 'prod_itemColor',
    materialIndex: 'prod_itemMaterial',
    marketAutocompleteIndex: 'prod_marketAutocomplete',
    similarBrands: 'https://lambda.services.sellpy.net/brandsimilarity/get_similar_brands',
    modelIndex: 'prod_itemModel'
  },
  facebookAppId: '356718581163051',
  googleTagManagerId: 'GTM-N355DPF',
  ga4Id: {
    SE: 'G-S70Y7SB5FS',
    DE: 'G-QP69N3KGWW',
    AT: 'G-Z9NNEPY11P',
    NL: 'G-XEEW9YK7Y4',
    EU: 'G-CYBVJBBJZK',
    DK: 'G-DG66HT6ZP4',
    PL: 'G-40C8ZDK6SN',
    FI: 'G-FXTHL8BB8B',
    FR: 'G-8BHRX9HYJ8',
    BE: 'G-KLTRDVK8XX',
    CZ: 'G-31C78F20NM'
  },
  googleAdwordsId: {
    SE: '957926314',
    DE: '670470762',
    AT: '472251744',
    NL: '472718201',
    EU: '389411838',
    PL: '10835042475',
    DK: '10835057868',
    FI: '10878768092',
    FR: '10952897085',
    CZ: '16833846107'
  },
  intercomId: 'pns84gny',
  parse: {
    applicationId: '3ebgwo1hPV0sk74fnWBTSW3RIxgw3b2ZAxM6qmCj',
    javascriptKey: 'hRVEXFeMQX8fB18ODYI9UvtlLkliB43qeaqUht3f',
    serverURL: 'https://sellpy-parse-prod.herokuapp.com/parse'
  },
  apollo: {
    uri: 'https://sellpy-parse-prod.herokuapp.com/graphql'
  },
  sentry: {
    dsn: 'https://fbb8308b5eee4b238ee4f770db29cf1c@o75716.ingest.sentry.io/4504197319294976',
    dsn_appPayments:
      'https://fe2d19acb3ff29ed8703bd8a5675cbff@o75716.ingest.sentry.io/4505764859609088'
  },
  ml: {
    clip_combined: {
      url: 'https://services.sellpy.net/clip/search/clip_combined',
      key: 'QhWW35c61hQ2G5V'
    },
    clip_image: {
      url: 'https://services.sellpy.net/clip/search/clip_image',
      key: 'QhWW35c61hQ2G5V'
    },
    similarItems: {
      url: 'https://services.sellpy.net/v1/recommendations/similaritems/getsimilar',
      key: '7QAmSxCDiPSWhIy'
    }
  },
  elasticMiddleware: {
    url: 'https://search.sellpy.net/elastic-middleware'
  },
  locize: {
    projectId: 'efceae1e-10ad-4d88-821d-7cc7deb8e32b'
  },
  sanity: {
    projectId: 'ilmr7lwv',
    dataset: 'production_v2',
    version: 'v2025-03-04'
  },
  trustedShops: {
    id: 'X3D2494439DABC5A580DD99FABE795975'
  },
  userBrands: {
    url: 'https://services.sellpy.net/v1/recommendations/userfeatures/getfeatures'
  },
  mapbox: {
    accessToken:
      'pk.eyJ1Ijoic2VsbHB5IiwiYSI6ImNsYmRvanI3cDAzazEzcGxkenNxZnJxbnQifQ.7kA0lpV2uEu2rLIFQ9U1bg'
  },
  firebase: {
    apiKey: 'AIzaSyB8G7cO4ydb-PJTVqJfzfpfDw6I6SAnz14',
    authDomain: 'sellpy-sellphone.firebaseapp.com',
    projectId: 'sellpy-sellphone',
    storageBucket: 'sellpy-sellphone.appspot.com',
    messagingSenderId: '591898297422',
    appId: '1:591898297422:web:5ddd99c9bb8723bcb26f1f',
    measurementId: 'G-GKNYP7DXMH'
  }
}
