import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Set } from 'immutable'
import { getItemsInCart } from '../../common/entities/selectors/checkout'
import { getAlgoliaItems } from '../../common/items/actions'
import { analyticsItemMapper } from '../../common/analytics/mappers'
import { useCurrentUserId } from '../../common/user/currentUser'

const useAnalyticsItems = () => {
  const dispatch = useDispatch()
  const userId = useCurrentUserId()
  const items = useSelector((state) => getItemsInCart(state, { userId }))
  const itemIds = items.map((i) => i.get('objectId')).toArray()
  const cachedItems = useSelector((state) => state.items.algoliaItems)
  const filteredCachedItems = cachedItems
    .toArray()
    .filter((item) => itemIds.includes(item.get('objectID')))
  const everyItemIsCached = filteredCachedItems.length === itemIds.length

  useEffect(() => {
    if (!everyItemIsCached) {
      dispatch(getAlgoliaItems(Set(itemIds)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(itemIds), dispatch])

  if (filteredCachedItems && everyItemIsCached) {
    return filteredCachedItems.map((item) => analyticsItemMapper(item))
  }
  return []
}

export default useAnalyticsItems
