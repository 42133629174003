import { fromJS } from 'immutable'
import Parse from '../../client/lib/parse'
import { cacheEntities } from '../entities/actions'

export const getPaymentAndPaidEntities = (paymentId) => async (dispatch) => {
  const { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
  dispatch(cacheEntities(payment))
  dispatch(cacheEntities(entities))
  return { payment, entities }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const pollPaymentAndPaidEntitiesForCompletion = (paymentId) => async (dispatch) => {
  let count = 0
  let { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
  while (!payment.get('completedAt') && count++ < 20) {
    await sleep(3000)
    ;({ payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId }))
  }
  dispatch(cacheEntities(payment))
  dispatch(cacheEntities(entities))
  return { payment, entities }
}

export const GET_PUBLIC_ORDER_DETAILS_FOR_ITEM = 'GET_PUBLIC_ORDER_DETAILS_FOR_ITEM'

export const getPublicOrderDetailsForItem = (itemId) => async (dispatch) => {
  const publicOrder = await Parse.Cloud.run('getPublicOrderDetailsForItem', { itemId })
  if (publicOrder) {
    dispatch({
      type: GET_PUBLIC_ORDER_DETAILS_FOR_ITEM,
      publicOrder: fromJS({ [publicOrder.objectId]: publicOrder })
    })
  }
}
