import React from 'react'
import { useSelector } from 'react-redux'
import { ICONS, Icon, Button, Span, IconButton } from '@sellpy/design-system-react-web'
import styled from 'styled-components'
import { SIZES } from '../../uiComponents'
import { mediaQueries } from '../../uiComponents/mediaQueries.js'
import { ownFreshReservationForItem } from '../../../common/entities/selectors/reservations.js'
import { useCurrentUserId } from '../../../common/user/currentUser'
import useFavorites, { getFormattedNumberOfWishlists } from './useFavorites.jsx'

const FavoriteToggleIconButton = styled(IconButton)`
  i {
    font-size: 1.5rem;
    ${({ filled, theme }) => (filled ? `color: ${theme.color.blue.default}` : '')};
  }
  ${mediaQueries.largerThanPhone} {
    i:hover {
      color: ${({ theme }) => theme.color.blue.default};
    }
  }
`

export const FavoriteToggleIcon = ({ onClick, filled }) => {
  return (
    <FavoriteToggleIconButton
      filled={filled}
      type='button'
      onClick={onClick}
      color='grey'
      icon={<Icon name={filled ? ICONS.HEART_FILLED : ICONS.HEART} />}
    />
  )
}

const FavoriteButtonInTile = ({ item, currentUser }) => {
  const itemId = item.get('objectID') || item.get('objectId')
  const { toggleFavorite, isFavorited } = useFavorites()
  const isOwnedByCurrentUser = currentUser && currentUser === item.get('user')
  const userId = useCurrentUserId()
  const ownReservation = useSelector((state) =>
    ownFreshReservationForItem(state, { itemId, userId })
  )

  return (
    <Button
      onClick={() => toggleFavorite(itemId)}
      style={{
        minWidth:
          isOwnedByCurrentUser || (!ownReservation && item.get('isReserved')) ? '100%' : '66px'
      }}
      color={'grey'}
      size={SIZES.SMALL}
      centerIcon={
        <div style={{ display: 'flex', alignContent: 'center', gap: '4px' }}>
          <Icon name={isFavorited(itemId) ? ICONS.HEART_FILLED : ICONS.HEART} />
          <Span design='body4' noMargin style={{ alignSelf: 'center' }}>
            {getFormattedNumberOfWishlists(
              isFavorited(itemId) ? item.get('favouriteCount') + 1 : item.get('favouriteCount')
            )}
          </Span>
        </div>
      }
    />
  )
}

export default FavoriteButtonInTile
