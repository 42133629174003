import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Spinner } from '@sellpy/design-system-react-web'
import { money } from '@sellpy/commons'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getCurrentUserIdentificationInformation } from '../../../common/dac7/actions.js'
import useUserTotalBalance from '../../balance/hooks/useUserTotalBalance.js'
import { requestCreditPayout } from '../../../common/payout/actions.js'
import { showToast } from '../../../common/ui/actions.js'
import DAC7 from '../../balance/components/DAC7.jsx'
import { SimpleModal } from '../../uiComponents/index.js'
import { Container } from '../../uiComponents/modal/styles.js'
import { commonPayoutErrors } from '../utils.js'
import { blockPayout } from '../../../common/dac7/selectors.js'
import { analyticsCreditConversion } from '../../../common/analytics/actions.js'
import useAnalyticsItems from '../../hooks/useAnalyticsItems.js'
import { CHECKOUT_TYPES } from '../../checkout/analytics.js'
import CreditPayoutComplete from './CreditPayoutComplete.jsx'
import CreditPayout from './CreditPayout.jsx'

const StyledSimpleModal = styled(SimpleModal)`
  ${Container} {
    max-width: 600px;
  }
`

const CreditPayoutModal = ({ isOpen, setIsOpen, onSubmitCallback, checkoutType }) => {
  const { t } = useTranslation(['balance', 'common'])
  const [creditPayoutComplete, setCreditPayoutComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [balance, refetch] = useUserTotalBalance()
  const methods = useForm({ mode: 'onChange' })
  const dispatch = useDispatch()
  const analyticsItems = useAnalyticsItems()

  const [openDAC7ModalForm, setOpenDAC7ModalForm] = useState(false)

  useEffect(() => {
    dispatch(getCurrentUserIdentificationInformation)
  }, [dispatch])

  const isPayoutBlocked = useSelector(blockPayout)

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset
  } = methods

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const { amount, currency } = money.toSubUnit({
        amount: data.amount,
        currency: balance.currency
      })

      const creditPayout = await dispatch(
        requestCreditPayout({
          amount,
          currency,
          region: process.env.REGION
        })
      )
      const analyticsPayload = () => {
        if (!checkoutType) return {}
        if (checkoutType === CHECKOUT_TYPES.CART) {
          return analyticsItems.length
            ? {
                checkoutType,
                items: analyticsItems,
                totalCreditsAvailable: balance?.amount,
                creditsConverted: amount
              }
            : {}
        }
        return { checkoutType }
      }
      analyticsCreditConversion(analyticsPayload())
      setCreditPayoutComplete(creditPayout)
    } catch (error) {
      dispatch(showToast(commonPayoutErrors(error, t)))
    } finally {
      setLoading(false)
      refetch()
      onSubmitCallback && onSubmitCallback()
      reset()
    }
  }

  const onClose = () => {
    setCreditPayoutComplete(false)
    setIsOpen(false)
  }

  let modalContent = loading ? <Spinner /> : <CreditPayout balance={balance} />
  modalContent = creditPayoutComplete ? (
    <CreditPayoutComplete creditPayout={creditPayoutComplete} />
  ) : (
    modalContent
  )

  const modalCTA =
    creditPayoutComplete || loading ? null : (
      <Button
        color={'black'}
        size={'medium'}
        variant={'filled'}
        label={t('credit.payout.accept')}
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
      />
    )

  return (
    <>
      {isPayoutBlocked ? (
        <DAC7
          openModalInfo={isOpen}
          setOpenModalInfo={onClose}
          openModalForm={openDAC7ModalForm}
          setOpenModalForm={setOpenDAC7ModalForm}
          showInfoBox={false}
        />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledSimpleModal
              headline={t('credit.payout.headline')}
              isOpen={isOpen}
              bottomCTA={modalCTA}
              onClose={onClose}
            >
              {modalContent}
            </StyledSimpleModal>
          </form>
        </FormProvider>
      )}
    </>
  )
}

export default CreditPayoutModal
