import React from 'react'
import { useTranslation } from 'react-i18next'
import { ICONS } from '@sellpy/design-system-react-web'
import config from 'config'
import { searchUrls } from '@sellpy/commons'
import { SuggestionItem, SuggestionList } from '../components/SuggestionItem.jsx'
import { SuggestionSection } from '../components/SuggestionSection.jsx'
import { locale } from '../../../../../../../common/region/locale.js'
import {
  categoryFromQuery,
  searchStateToTitle
} from '../../../../../../search/components/searchStateTools.js'
import { region } from '../../../../../../../common/region/region.js'
const { environment } = config

export const LatestSearches = ({ items, source, getListProps, getItemProps }) => {
  const { t } = useTranslation('search')

  return (
    <SuggestionSection title={t('suggestions.latestSearches.header')}>
      <SuggestionList {...getListProps()}>
        {items.map((item) => {
          const searchState = searchUrls.parseSearchStringV2({
            region: region(),
            locale,
            env: environment
          })(new URL(window.location.origin + item.path))
          const title = item.title || searchStateToTitle({ searchState, t })
          const category = categoryFromQuery(searchState)?.[0]

          return (
            <SuggestionItem
              key={item.path}
              title={title}
              subTitle={category}
              icon={ICONS.PENDING_CLOCK}
              getItemProps={getItemProps}
              item={item}
              source={source}
            />
          )
        })}
      </SuggestionList>
    </SuggestionSection>
  )
}
