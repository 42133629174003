import { fromJS, List } from 'immutable'
import { createSelector } from 'reselect'
import config from 'config'
const { staticImageAssets } = config

export const mapToAlgoliaItems = (itemIds, algoliaItems) =>
  itemIds
    .map((itemId) => algoliaItems.get(itemId))
    // filter non-existing items since itemIds are sometimes populated
    // at a different time than algoliaItems (i.e last visited items)
    .filter(Boolean)

export const lastVisitedItemsSelector = createSelector(
  [(state) => state.marketLanding.lastVisitedItemIds, (state) => state.items.algoliaItems],
  mapToAlgoliaItems
)

const getBrandTileUrl = (brand) =>
  `${staticImageAssets}market/landing/brandTiles/tile${(brand.charCodeAt(0) % 7) + 1}-2.jpg`

export const similarBrandsSelector = createSelector(
  [(_, { brand }) => brand, (state) => state.items.similarBrands],
  (brand, similarBrands) => {
    const brands = brand ? similarBrands.get(brand, List()) : List()
    return brands
      .toOrderedSet()
      .toList()
      .map((brand) =>
        fromJS({
          id: brand,
          overlayText: brand,
          imageUrl: getBrandTileUrl(brand),
          path: `/store/brand/${encodeURIComponent(brand)}`
        })
      )
  }
)

export const sellerItemsUserSelector = createSelector(
  [(_, { userId }) => userId, (state) => state.items.algoliaItems],
  (userId, algoliaItems) => {
    if (!userId) return List()
    return filterAlgoliaItemsByUserId(userId, algoliaItems).toList()
  }
)

const filterAlgoliaItemsByUserId = (userId, algoliaItems) => {
  return algoliaItems
    .filter((algoliaItem) => algoliaItem.get('user') === userId)
    .sortBy(
      (algoliaItem) => algoliaItem.get('createdAt'),
      (a, b) => b - a
    )
}

export const recommendedItemsBasedOnSegmentSelector = createSelector(
  [
    (_, { items, ignoredItems }) => ({ items, ignoredItems }),
    (state) => state.items.similarItems,
    (state) => state.items.algoliaItems
  ],
  ({ items, ignoredItems = [] }, similarItems, algoliaItems) => {
    const itemIds = items
      .toList()
      .map((itemId) => similarItems.get(itemId, List()))
      .flatten()
      .toSet()
      .subtract(ignoredItems) // don't include favorites, e.g.
      .toList() // remove duplicates
      .sort() // sort in order to "randomize" the results in a deterministic way
      .take(30)
    return mapToAlgoliaItems(itemIds, algoliaItems)
  }
)

export const similarItemsForItemSelector = createSelector(
  [
    (_, { itemId }) => itemId,
    (state) => state.items.similarItems,
    (state) => state.items.algoliaItems
  ],
  (itemId, similarItems, algoliaItems) => {
    const itemIds = itemId ? similarItems.get(itemId, List()) : List()
    return mapToAlgoliaItems(itemIds, algoliaItems)
  }
)
