import React from 'react'
import { useTranslation } from 'react-i18next'
import { marketplace, user as userTools, itemCategoryMapper } from '@sellpy/commons'
import { AsymmetricBanner, HeroBanner } from '@sellpy/design-system-react-web'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useResponsive } from '../hooks'
import TwoTileBanner from '../uiComponents/banner/twoTile/TwoTileBanner.jsx'
import { largerThanLandingPageMaxWidth } from '../uiComponents/mediaQueries'
import { region } from '../../common/region/region'
import { trackClickAsymmetricBanner } from '../../common/analytics/actions'
import { useCurrentParseUser } from '../../common/user/currentUser'
import NewsLetter from '../components/newsletter/NewsLetter.jsx'
import { locale } from '../../common/region/locale.js'
import { BrandsChipBanner } from './content/ChipBanners.jsx'
import { LandingUSP } from './content/USP.jsx'
const { BASE_URLS } = marketplace

const publicAssetsBaseUrl = 'https://public-assets.images.sellpy.net/webApp/fallbackLanding/'

const HeroBannerWrapper = styled.div`
  ${largerThanLandingPageMaxWidth} {
    margin-inline: 32px;
  }
`

export const FallbackLandingPageContent = () => {
  const location = useLocation()
  const segmentValue = location.pathname.replaceAll('/', '')
  const { t } = useTranslation(['fallbackLanding', 'menu'])

  const { isMobile } = useResponsive()
  const parseUser = useCurrentParseUser()
  const loggedIn = userTools.userIsFull(parseUser)

  const assymetricBannerConfig = !loggedIn
    ? {
        mirrored: true,
        headline: t('assymetricHeadlineGuest'),
        id: 'sellpy_circle_banner',
        ctaText: t('assymetricCtaTextGuest'),
        ctaUrl: `${BASE_URLS[region()]}/login`,
        imagePath: publicAssetsBaseUrl + 'sellpy-circle-2400x1600.jpg'
      }
    : {
        id: 'sellpy_circle_banner',
        headline: t('assymetricBannerHeadline'),
        body: t('assymetricBannerBody'),
        ctaText: t('assymetricCtaText'),
        ctaUrl: `${BASE_URLS[region()]}/circle`,
        imagePath: publicAssetsBaseUrl + 'sellpy-circle-2400x1600.jpg'
      }

  return (
    <>
      <HeroBannerWrapper>
        <HeroBanner
          headline={t('heroBannerHeadline')}
          body={t('heroBannerBody')}
          imageUrl={
            publicAssetsBaseUrl +
            (isMobile
              ? 'fallback-herobanner-mobile-DARKER-1200x960px.jpg'
              : 'fallback-herobanner-desktop-2048x615px.jpg')
          }
          color={isMobile ? 'white' : 'black'}
          buttons={[
            {
              link: `/search`,
              color: 'white',
              label: t('heroBannerCtaLabel')
            }
          ]}
          ratio={isMobile ? 100 : 30}
          alignment={'left'}
        />
      </HeroBannerWrapper>
      <TwoTileBanner
        headline={t('twoTileBannerHeadline')}
        leftTile={{
          toUrl: `${BASE_URLS[region()]}/search/${itemCategoryMapper.baseCategories(locale).women}`,
          desktopImageUrl: publicAssetsBaseUrl + 'fallback-women-twotile-desktop-914x457px.jpg',
          mobileImageUrl: publicAssetsBaseUrl + 'fallback-women-twotile-mobile-216x216px.jpg',
          label: itemCategoryMapper.baseCategories(locale).women,
          textPlacement: 'right',
          textColor: 'black'
        }}
        rightTile={{
          toUrl: `${BASE_URLS[region()]}/search/${itemCategoryMapper.baseCategories(locale).men}`,
          desktopImageUrl: publicAssetsBaseUrl + 'fallback-men-twotile-desktop-914x457px.jpg',
          mobileImageUrl: publicAssetsBaseUrl + 'fallback-men-twotile-mobile-216x216px.jpg',
          label: itemCategoryMapper.baseCategories(locale).men,
          textPlacement: 'right',
          textColor: 'black'
        }}
      />
      <TwoTileBanner
        headline={''}
        leftTile={{
          toUrl: `${BASE_URLS[region()]}/search/${
            itemCategoryMapper.baseCategories(locale).children
          }`,
          desktopImageUrl: publicAssetsBaseUrl + 'fallback-kids-twotile-desktop-914x457px.jpg',
          mobileImageUrl: publicAssetsBaseUrl + 'fallback-kids-twotile-mobile-216x216px.jpg',
          label: itemCategoryMapper.baseCategories(locale).children,
          textPlacement: 'right',
          textColor: 'black'
        }}
        rightTile={{
          toUrl: `${BASE_URLS[region()]}/search/${
            itemCategoryMapper.baseCategories(locale).things
          }`,
          desktopImageUrl: publicAssetsBaseUrl + 'fallback-things-twotile-desktop-914x457px.jpg',
          mobileImageUrl: publicAssetsBaseUrl + 'fallback-things-twotile-mobile-216x216px.jpg',
          label: itemCategoryMapper.baseCategories(locale).things,
          textPlacement: 'right',
          textColor: 'black'
        }}
      />
      <AsymmetricBanner
        {...assymetricBannerConfig}
        onClick={() => {
          trackClickAsymmetricBanner({
            segment: segmentValue,
            campaign: t('assymetricBannerHeadline'),
            position: 3
          })
        }}
      />
      <BrandsChipBanner />
      <NewsLetter />
      <LandingUSP />
    </>
  )
}
