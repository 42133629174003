import React from 'react'
import { useTranslation } from 'react-i18next'
import { ICONS } from '@sellpy/design-system-react-web'
import { SuggestionItem, SuggestionList } from '../components/SuggestionItem.jsx'
import { SuggestionSection } from '../components/SuggestionSection.jsx'

export const Categories = ({ items, source, getListProps, getItemProps }) => {
  const { t } = useTranslation('search')

  return (
    <SuggestionSection title={t('suggestions.categories.header')}>
      <SuggestionList {...getListProps()}>
        {items.map((item) => {
          const categorySplit = item.label.split(' > ')
          return (
            <SuggestionItem
              key={item.label}
              title={categorySplit[categorySplit.length - 1]}
              subTitle={categorySplit[0]}
              icon={ICONS.CATEGORY}
              getItemProps={getItemProps}
              item={item}
              source={source}
            />
          )
        })}
      </SuggestionList>
    </SuggestionSection>
  )
}
