import { Record } from 'immutable'
import { SET_COLLAB } from './actions'
import { HM, FLATTERED, KLARNA, SNAPCHAT, FASHIONWEEK, FRIDAY, ANDC } from './config'

const InitialState = Record({
  [HM]: false,
  [FLATTERED]: false,
  [KLARNA]: false,
  [SNAPCHAT]: false,
  [FASHIONWEEK]: false,
  [FRIDAY]: false,
  [ANDC]: false
})

export default function collaborationReducer(state = new InitialState(), action) {
  switch (action.type) {
    case SET_COLLAB:
      return state.set(action.name, action.value)
  }
  return state
}
