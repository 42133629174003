/* global IntersectionObserver */
import 'intersection-observer'
import { useEffect, useState } from 'react'
import { DataLayer } from '../../common/analytics/dataLayer'

export const CHECKOUT_TYPES = {
  CART: 'cart',
  CONTAINER: 'container',
  GIFTCARD: 'giftcard'
}

const useElementHasBeenInViewport = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const [hasBeenInViewPort, setHasBeenInViewPort] = useState(false)

  const element = ref && ref.current
  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
    threshold: 0.1
  })

  useEffect(() => {
    if (!element) return
    observer.observe(element)
    return () => {
      observer.disconnect()
    }
    // don't include observer in dependency array as it will trigger
    // new instances of observer that will break the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element])

  useEffect(() => {
    if (isIntersecting && !hasBeenInViewPort) {
      setHasBeenInViewPort(true)
    }
  }, [isIntersecting, hasBeenInViewPort])

  return hasBeenInViewPort
}

export const useCartAnalyticsViewportWithTrigger = ({
  payload,
  analyticsFunction,
  ref,
  trigger
}) => {
  const delay = 1000
  const [timeoutId, setTimeoutId] = useState(null)
  const elementHasBeenInViewport = useElementHasBeenInViewport(ref)
  useEffect(() => {
    if (payload && elementHasBeenInViewport && typeof analyticsFunction === 'function' && trigger) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      const id = setTimeout(() => {
        analyticsFunction(payload)
      }, delay)
      setTimeoutId(id)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(payload), elementHasBeenInViewport, trigger])
}

export const useAnalyticsViewCart = (payload) => {
  const dependencyKey = payload?.items.map((item) => item.item_id).join('-')
  useEffect(() => {
    if (!payload) return
    DataLayer.trackCartView(payload)
    // don't include items in dependency array
    // because of referential equality, it's different on every re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyKey])
}
