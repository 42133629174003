import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getSimilarItems } from '../../../common/items/actions'
import { similarItemsForItemSelector } from '../../../common/marketLanding/selectors'

const FAVORITED_HITS_SESSION_STORAGE_KEY = 'FAVORITED_HITS'

/** @typedef {Object.<string, string[]>} SimilarItems */

/**
 * @returns {SimilarItems}
 */
const getFavoritedHits = () =>
  JSON.parse(window.sessionStorage.getItem(FAVORITED_HITS_SESSION_STORAGE_KEY) || '{}')

/**
 * @param {SimilarItems} favoritedHits
 */
const setFavoritedHits = (favoritedHits) =>
  window.sessionStorage.setItem(FAVORITED_HITS_SESSION_STORAGE_KEY, JSON.stringify(favoritedHits))

export const useRegisterFavoritedHitIfInSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  /**
   * Register that an item was favorited on the PLP, or on a PDP that was reached from the PLP.
   */
  const registerFavoritedHitIfInSearch = ({ itemId }) => {
    const isOnPLP = location.pathname.startsWith('/search')
    const isOnPDPFromPLP =
      location.pathname.startsWith('/item') && location.state?.from?.startsWith('/search')

    if (!isOnPLP && !isOnPDPFromPLP) return

    // Fetch the similar items for this favorited item, so that they are available instantly when the user navigates to the next PLP page.
    dispatch(getSimilarItems([itemId]))

    const searchPathAndQuery = isOnPLP ? location.pathname + location.search : location.state?.from

    const url = new URL(window.location.origin + searchPathAndQuery)
    const page = Number(url.searchParams.get('page') || 1)

    // We want to target the next PLP page.
    url.searchParams.set('page', page + 1)
    url.searchParams.sort()
    const targetSearchPathAndQuery = url.pathname + url.search

    const favoritedHits = getFavoritedHits()
    const hitsForNextPage = favoritedHits[targetSearchPathAndQuery] || []
    favoritedHits[targetSearchPathAndQuery] = [...new Set([...hitsForNextPage, itemId])]
    setFavoritedHits(favoritedHits)
  }

  return { registerFavoritedHitIfInSearch }
}

export const useFavoritedHitsSimilarItems = (searchPathAndQuery) => {
  const dispatch = useDispatch()

  const favoritedHits = getFavoritedHits()

  const url = new URL(window.location.origin + searchPathAndQuery)
  url.searchParams.sort()
  const targetSearchPathAndQuery = url.pathname + url.search
  const hitsForCurrentPage = favoritedHits[targetSearchPathAndQuery] || []

  // Fetch the similar items for all favorited hits on the current page, in case they haven't been fetched yet.
  useEffect(() => {
    dispatch(getSimilarItems(hitsForCurrentPage))
  }, [hitsForCurrentPage, dispatch])

  const similarItemIdsForItemIds = useSelector((state) =>
    hitsForCurrentPage.reduce((acc, itemId) => {
      const similarItems = similarItemsForItemSelector(state, { itemId })
      acc[itemId] = similarItems.toJS()
      return acc
    }, {})
  )

  const longestSimilarItemsArrayLength = Math.max(
    ...hitsForCurrentPage.map((itemId) => similarItemIdsForItemIds[itemId]?.length || 0)
  )

  // Sort similar items so that they take turns, instead of showing all similar items for one favorited hit first.
  const sortedSimilarItems = []
  for (let i = 0; i < longestSimilarItemsArrayLength; i++) {
    for (const itemId of hitsForCurrentPage) {
      const similarItems = similarItemIdsForItemIds[itemId]
      if (similarItems?.[i]) {
        sortedSimilarItems.push(similarItems[i])
      }
    }
  }

  return sortedSimilarItems
}
