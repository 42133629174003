import Parse from 'parse'
import { Map } from 'immutable'
import { DateTime, Duration } from 'luxon'
import { getCurrentParseUser } from '../user/currentUser'

export const parseObject = (name, id) => {
  const obj = new Parse.Object(name)
  obj.id = id
  return obj
}

const TTL = Duration.fromObject({ minutes: 10 })

let cache = Map()

/**
 * Calls a cloud function with the given name, data & options and creates a cache key based on those input values.
 * Given a cache hit in a subsequent call, no cloud function is called and the result is returned from the cache.
 *
 * @param {function} onNewResult The callback function onNewResult is called every time a new result is fetched.
 * This means that in contrast to using the return value of runCloudCached, this function is not called when the result is fetched from the cache.
 * Utilize this to minimize re-renders as the result is already in the redux store.
 * @param {Object} cacheOptions
 * @param {boolean} cacheOptions.cacheBust If true, the cache is cleared before the cloud function is called.
 * @param {string} name The name of the cloud function to call.
 * @param {Object} data The data to pass to the cloud function.
 * @param {Object} options The options to use when running the cloud function.
 * @returns {*} the result of either a cache hit or a called cloud function
 */
export const runCloudCached = (onNewResult, cacheOptions) => async (name, data, options) => {
  const key = [
    name,
    ...[data, options, getCurrentParseUser()?.id].filter(Boolean).map(JSON.stringify)
  ].join('-')
  if (cacheOptions?.cacheBust) {
    cache = cache.set(key, null)
  }
  const cacheEntry = cache.get(key)
  if (cacheEntry && cacheEntry.get('validUntil') > DateTime.local()) {
    return cacheEntry.get('result')
  } else {
    cache = cache.set(
      key,
      Map({
        validUntil: DateTime.local().plus(TTL),
        result: Parse.Cloud.run(name, data, options)
      })
    )
    try {
      const result = await cache.getIn([key, 'result'])
      if (onNewResult) onNewResult(result)
      return result
    } catch (error) {
      cache = cache.delete(key)
      throw error
    }
  }
}

export const clearCloudCache = () => (cache = Map())

let queryCache = Map()

export const runQueryCached = (onNewResult, cacheOptions) => async (query) => {
  const key = JSON.stringify(query.toJSON())
  if (cacheOptions?.cacheBust) {
    queryCache = queryCache.set(key, null)
  }
  const cacheEntry = queryCache.get(key)
  if (cacheEntry && cacheEntry.get('validUntil') > DateTime.local()) {
    return cacheEntry.get('result')
  } else {
    queryCache = queryCache.set(
      key,
      Map({
        validUntil: DateTime.local().plus(TTL),
        result: query.find()
      })
    )
    try {
      const result = await queryCache.getIn([key, 'result'])
      if (onNewResult) onNewResult(result)
      return result
    } catch (error) {
      queryCache = queryCache.delete(key)
      throw error
    }
  }
}
