import { createSelector } from 'reselect'
import { Map } from 'immutable'
import { getEntity } from '../entities/selectors/base'

export const ItemValuationSelector = createSelector(
  [(_, itemId) => itemId, getEntity('ItemValuation')],
  (itemId, itemValuations) =>
    itemValuations.find((itemValuation) => itemValuation.get('item') === itemId)
)

export const saleRequestForItemSelector = createSelector(
  [(_, itemId) => itemId, getEntity('SaleRequest')],
  (itemId, saleRequests) => saleRequests.find((saleRequest) => saleRequest.get('item') === itemId)
)

export const itemValuesSelector = (itemIds) => (state) =>
  itemIds.reduce((map, itemId) => map.set(itemId, state.items.itemValues.get(itemId)), Map())

export const resultsInSyncWithItemEntitiesSelector = createSelector(
  [(_, elasticResults) => elasticResults, getEntity('Item')],
  (elasticResults, items) =>
    elasticResults.map((elasticResult) =>
      items.get(elasticResult.get('objectId'))
        ? elasticResult.set(
            'itemStatus',
            items.getIn([elasticResult.get('objectId'), 'itemStatus'])
          )
        : elasticResult
    )
)

export const sendBackRequestForItemIdSelector = createSelector(
  [getEntity('SendBackRequest'), (_, itemId) => itemId],
  (sendBackRequests, itemId) =>
    sendBackRequests.find((sendBackRequest) => sendBackRequest.get('item') === itemId)
)

export const getIsBusinessSaleSelector = (state, items) =>
  items.reduce(
    (map, item) => map.set(item.get('objectId'), state.items.isBusinessSale.get(item.get('bag'))),
    Map()
  )
