import Parse from 'parse'
import { makeVar, useReactiveVar } from '@apollo/client'
import { clearPasswordlessLoginError } from '../../client/login/passwordlessLoginError'
import { refetchCurrentUser, resetCurrentUserCache } from './currentApolloUser'
/** @import { ReactiveVar } from "@apollo/client" */

/** @type {ReactiveVar<Parse.User<Parse.Attributes> | null>} */
const parseUserVar = makeVar(null)

/**
 * Should only be used as part of the parse initialization process
 */
export const _initCurrentUser = () => {
  // eslint-disable-next-line no-restricted-syntax
  parseUserVar(Parse.User.current())
}

/**
 * @deprecated use the getCurrentUser function instead
 */
export const getCurrentParseUser = () => parseUserVar()

/**
 * @deprecated use the useCurrentUser hook instead
 */
export const useCurrentParseUser = () => useReactiveVar(parseUserVar)

/**
 * @returns {string | undefined}
 */
export const useCurrentUserId = () => useCurrentParseUser()?.id

/**
 * Logs the current user out and updates state accordingly.
 */
export const logoutCurrentUser = async () => {
  await Parse.User.logOut()
  parseUserVar(null)
  resetCurrentUserCache()
}

/**
 * Keep the current user object up to date after logging in or signing up.
 * @param {string} sessionToken
 */
export const becomeUser = async (sessionToken) => {
  const parseUser = await Parse.User.become(sessionToken)
  return setCurrentUser(parseUser)
}

/**
 * Sets the current user, this is for usages when we update the user object somehow.
 * Login and signup should use `becomeUser` instead.
 * @param {Parse.User<Parse.Attributes>} parseUser
 */
export const setCurrentUser = async (parseUser) => {
  parseUserVar(parseUser)
  clearPasswordlessLoginError()
  refetchCurrentUser()
  return parseUser
}
