import { searchStateTools } from '@sellpy/commons'
import { Set } from 'immutable'
import { getAlgoliaItems } from '../items/actions'
import { INIT_LOCALIZED_ITEM_INDEX } from '../lib/algolia'
import { getCurrentParseUser } from '../user/currentUser'
import { getCurrentLoyaltyProgramPeriod } from '../entities/selectors/loyaltyProgramPeriod'
import { isLoyaltyEnabled } from '../region/region'

export const fetchLastVisitedItems = () => (dispatch, getState) => {
  dispatch(getAlgoliaItems(Set(getState().marketLanding.lastVisitedItemIds)))
}

export const FETCH_SELLER_ITEMS_SUCCESS = 'FETCH_SELLER_ITEMS_SUCCESS'

export const fetchSellerItems = (userId) => async (dispatch, getState) => {
  const firstAccessEligibility =
    !isLoyaltyEnabled || !!getCurrentLoyaltyProgramPeriod(getState(), { userId })
  const loggedInUserId = getCurrentParseUser()?.id
  const { hits } = await INIT_LOCALIZED_ITEM_INDEX.search('', {
    filters: `${searchStateTools.composeDefaultAlgoliaFilters({
      firstAccessEligibility,
      sellerUserId: loggedInUserId,
      region: process.env.REGION
    })} AND user:${userId}`,
    hitsPerPage: 20
  })
  dispatch({ type: FETCH_SELLER_ITEMS_SUCCESS, hits })
}

export const TRACK_ITEM_VISIT = 'TRACK_ITEM_VISIT'
export const trackItemVisit = (itemId) => ({ type: TRACK_ITEM_VISIT, itemId })
