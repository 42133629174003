import Parse from '../../client/lib/parse'
import { cacheEntities } from '../entities/actions'
import { runCloudCached } from '../lib/parseTools'
import { getCurrentParseUser } from '../user/currentUser'

export const GET_CURRENT_PERIOD = 'GET_CURRENT_PERIOD'
export const getCurrentPeriodForUser = ({ region }) => async (dispatch) => {
  const user = getCurrentParseUser()?.id
  return runCloudCached((period) => {
    if (period) {
      dispatch(cacheEntities(period))
    }
  })('getCurrentPeriodForUser', {
    user,
    region
  }).catch(console.error)
}

export const SET_LOYALTY_START = 'SET_LOYALTY_START'
export const getStartOfLoyaltyForUser = ({ region }) => async (dispatch) => {
  const userId = getCurrentParseUser().id
  return runCloudCached((value) => {
    if (value) {
      dispatch({ type: SET_LOYALTY_START, userId, value })
    }
  })('getStartOfLoyaltyForUser', {
    userId,
    region
  }).catch(console.error)
}

export const cancelLoyaltyProgram = ({ user, region }) => async () => {
  return Parse.Cloud.run('cancelLoyaltyProgram', { user, region }).catch(console.error)
}

export const createSessionLoyaltyProgram = (data) => {
  return Parse.Cloud.run('createSessionLoyaltyProgram', {
    ...data,
    region: process.env.REGION
  })
}

export const commenceSessionLoyaltyProgram = (data) => {
  return Parse.Cloud.run('commenceSessionLoyaltyProgram', {
    ...data,
    region: process.env.REGION
  })
}
