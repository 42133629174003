import Parse from 'parse'
import { region } from '../../common/region/region'
import { getABTestFraction } from '../../common/analytics/abTest'
import { _initCurrentUser } from '../../common/user/currentUser'
export default Parse

export function initializeParse(parseConfig) {
  const { applicationId, javascriptKey, serverURL } = parseConfig
  Parse.initialize(applicationId, javascriptKey)
  Parse.serverURL = serverURL
  Parse.CoreManager.set('REQUEST_ATTEMPT_LIMIT', 0)
  Parse.CoreManager.set('REQUEST_HEADERS', {
    region: region(),
    'client-ab-test-fraction': getABTestFraction()
  })
  Parse.Object.disableSingleInstance()
  _initCurrentUser()
}
