import { Map, Record } from 'immutable'
import * as actions from './actions'

const InitialState = Record({
  dataUsageConsent: Map(),
  dataUsageConsentLoaded: false
})

const initialState = new InitialState()

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DATA_USAGE_CONSENT_RETRIEVED: {
      return state
        .update('dataUsageConsent', (consentMap) => consentMap.merge(action.consentMap))
        .set('dataUsageConsentLoaded', true)
    }
    case actions.DATA_USAGE_CONSENT_SET: {
      return state.setIn(['dataUsageConsent', action.dataType], action.consent)
    }
  }

  return state
}
