import React from 'react'
import styled from 'styled-components'
import { imageTools } from '@sellpy/commons'
import { P } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../uiComponents/mediaQueries.js'

const DEFAULT_OVERLAP = 30
const DEFAULT_DIAMETER = 51
const DEFAULT_BORDER_WIDTH = 2
const DEFAULT_UNIT = 'px'

const BaseDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  width: ${({ baseWidth, unit }) => `${baseWidth}${unit}`};
  ${mediaQueries.largerThanTablet} {
    width: ${({ baseWidth, unit, overlap }) => `${baseWidth + overlap}${unit}`};
  }
`

export const ImageStackContainer = styled.div`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
`

export const CircularImageContainer = styled.div`
  border-radius: 50%;
  border-color: ${({ theme }) => theme.color.grey.shade8};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-style: solid;
  border-width: 1px;
  width: ${({ diameter, unit }) => `${diameter}${unit}`};
  height: ${({ diameter, unit }) => `${diameter}${unit}`};
  overflow: hidden;
  transition: ${({ animate }) => animate && 'margin-right 0.25s ease-in'};
  &:not(:last-child) {
    margin-right: ${({ overlap, unit }) => `-${overlap}${unit}`};
    ${mediaQueries.largerThanTablet} {
      :hover {
        margin-right: ${({ animate }) => animate && '-0.5rem'};
      }
    }
  }
`

const CoverImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const AdditionalIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const AdditionalIndicatorText = styled(P)`
  border-radius: 2px;
  padding: 0px 2px;
`

const optimizeImages = (images) =>
  images.map((image) => imageTools.optimizeImage(image, { width: 400 }))

export const ImageStack = ({
  images = [],
  svgs = [],
  stackConfig: {
    overlap = DEFAULT_OVERLAP,
    diameter = DEFAULT_DIAMETER,
    borderWidth = DEFAULT_BORDER_WIDTH,
    unit = DEFAULT_UNIT
  } = {},
  count,
  limit,
  className,
  alignment = 'center',
  animate = true,
  showAdditionalIndicator = true
}) => {
  const baseWidth = (limit + 1) * 2 * borderWidth + diameter + (diameter - overlap) * limit
  const elementsCount = images.length || svgs.length

  return (
    <BaseDiv overlap={overlap} baseWidth={baseWidth} unit={unit} className={className}>
      <ImageStackContainer alignment={alignment}>
        {images &&
          images.length > 0 &&
          optimizeImages(images)
            .slice(0, limit)
            .map((image, index) => (
              <CircularImageContainer
                animate={animate}
                diameter={diameter}
                unit={unit}
                overlap={overlap}
                key={image + index}
              >
                <CoverImage src={image} alt='cart-thumbnail' />
              </CircularImageContainer>
            ))}
        {svgs &&
          svgs.length > 0 &&
          svgs.slice(0, limit).map((Svg, index) => (
            <CircularImageContainer
              animate={animate}
              diameter={diameter}
              unit={unit}
              overlap={overlap}
              key={index}
              backgroundColor='white'
            >
              <Svg />
            </CircularImageContainer>
          ))}
      </ImageStackContainer>
      {showAdditionalIndicator && !count && elementsCount > limit && (
        <AdditionalIndicator count={elementsCount - limit} />
      )}
      {showAdditionalIndicator && count && count > limit && (
        <AdditionalIndicator count={count - limit} />
      )}
    </BaseDiv>
  )
}

const AdditionalIndicator = ({ count }) => (
  <AdditionalIndicatorContainer>
    <AdditionalIndicatorText center noMargin>
      + {count}
    </AdditionalIndicatorText>
  </AdditionalIndicatorContainer>
)
