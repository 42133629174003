#import "../apollo/localizedMetadata.fragment.gql"
#import "../p2p/listCircleItem/ListCircleItem.fragment.gql"

query getOrderOverview(
  $id: ID!
  $isSV: Boolean!
  $isDE: Boolean!
  $isEN: Boolean!
  $isNL: Boolean!
  $isDA: Boolean!
  $isPL: Boolean!
  $isFI: Boolean!
  $isFR: Boolean!
  $isCS: Boolean!
) {
  parcelStatusAlerts: fetchPackageAlertsByOrder(orderId: $id) {
    objectId
    parcelNo
    alertTime
    status
  }
  itemClaims(where: { order: { have: { objectId: { equalTo: $id } } } }, first: 1000) {
    edges {
      node {
        objectId
        status
        item {
          objectId
        }
        offer
        type
        order {
          objectId
        }
        paidAt
      }
    }
  }
  lineItems: fetchLineItemsByOrder(orderId: $id) {
    objectId
    discountAmount
    amountIncludingTax
    type
  }
  containerOrders(where: { order: { have: { objectId: { equalTo: $id } } } }) {
    edges {
      node {
        objectId
        count
      }
    }
  }
  order(id: $id) {
    objectId
    p2p
    status
    freight
    freightPayment
    orderPayment
    toAddress
    datePaid
    dateCanceled
    dateDelivered
    itemPayments {
      ... on Element {
        value
      }
    }
    orderPackagingInfo {
      ... on Element {
        value
      }
    }
    user {
      ... on User {
        objectId
        email
      }
    }
    payment {
      ... on Payment {
        objectId
        creditAmount
        adyenPaymentMethod
        metadata
      }
    }
    items {
      ... on Item {
        objectId
        ...localizedMetadataFragment
        images {
          ... on Element {
            value
          }
        }
        ...ListCircleItemFragment
        itemIO {
          objectId
          bodyOutputOrder {
            ... on Element {
              value
            }
          }
          titleOutputOrder {
            ... on Element {
              value
            }
          }
        }
      }
    }
    region
  }
}
