import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { marketplace, money } from '@sellpy/commons'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Chip, P } from '@sellpy/design-system-react-web'
import { gql, useQuery } from '@apollo/client'
import { fetchUserCredits } from '../../../../common/cart/actions'
import { FEATURE_SELL, isFeatureEnabled } from '../../../featureToggle/index.jsx'
import { region } from '../../../../common/region/region'
import useUserTotalBalance from '../../../balance/hooks/useUserTotalBalance'
import { useMaxCreditAmount } from '../hooks/useMaxCreditAmount'
import { analyticsViewCreditConversion } from '../../../../common/analytics/actions'
import { useAnalyticsItems } from '../../../hooks'
import { CHECKOUT_TYPES } from '../../../checkout/analytics'
import CreditsCheckbox from './CreditsCheckbox.jsx'

const ConvertWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 16px;
`

const ConvertCredits = ({ handleOnClick, creditsInSubUnits, inCreditBonusIncreaseGroup }) => {
  const { t } = useTranslation('adyenSessionPayment')
  const theme = useTheme()
  return (
    <ConvertWrapper>
      <div>
        <P noMargin design='body2'>
          {t('creditsNudge.convert.label')}
        </P>
        <P design='body3' style={{ color: theme.color.grey.shade3 }} noMargin>
          {inCreditBonusIncreaseGroup
            ? t('balance:totalBalance.creditConversionTip')
            : t('creditsNudge.convert.tip', { percentageExtra: 5 })}
        </P>
      </div>
      <Chip
        variant='outlined'
        title={t('creditsNudge.convert.chipLabel', {
          context: creditsInSubUnits?.amount > 1 ? 'hasCredits' : 'noCredits'
        })}
        onClick={handleOnClick}
      />
    </ConvertWrapper>
  )
}

const InfoBlock = styled(P)`
  display: block;
  margin: 0;
`

const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.color.green.shade10};
  padding: 16px;
`

const Wrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${({ theme }) =>
    `0px 2px 4px 0px ${theme.color.grey.shade1}29, 0px 4px 4px 0px ${theme.color.grey.shade1}1f`};
  margin-bottom: 16px;
`

const GET_CREDIT_MULTIPLIER = gql`
  query getCreditMultiplier {
    creditMultiplier: getCreditMultiplier
  }
`

const CreditsV2 = ({ totalAmount, register, onChange, loading, handleOnClick, checkoutType }) => {
  const { t } = useTranslation(['adyenSessionPayment', 'balance'])
  const dispatch = useDispatch()
  const [userTotalBalance] = useUserTotalBalance()
  const [tracked, setTracked] = useState(false)

  const { data: creditData, loading: creditMultiplierLoading } = useQuery(GET_CREDIT_MULTIPLIER)

  const analyticsItems = useAnalyticsItems()
  useEffect(() => {
    if (tracked || !checkoutType) return

    const analyticsPayload = (() => {
      if (checkoutType === CHECKOUT_TYPES.CART) {
        return analyticsItems.length
          ? {
              checkoutType,
              items: analyticsItems
            }
          : null
      }
      return { checkoutType }
    })()

    if (!analyticsPayload) return

    analyticsViewCreditConversion(analyticsPayload)
    setTracked(true)
  }, [checkoutType, tracked, analyticsItems])

  useEffect(() => {
    dispatch(fetchUserCredits(region()))
  }, [dispatch])
  const maxCreditAmount = useMaxCreditAmount(totalAmount)
  const availableCredits = useSelector((state) => state.cart.credit)
  const creditsInSubUnits = money.toSubUnit({
    amount: availableCredits,
    currency: marketplace.CURRENCY[region()]
  })
  if (money.toBaseUnit(maxCreditAmount).amount <= 0 && userTotalBalance?.amount <= 0) return null
  const { onChange: formOnChange, ...hookFormFieldProps } = register('useCredits')

  if (creditMultiplierLoading) return null
  const creditMultiplier = creditData?.creditMultiplier
  const inCreditBonusIncreaseGroup = creditMultiplier > 1.05

  return (
    <Wrapper>
      <InfoContainer>
        <Balance userTotalBalance={userTotalBalance} creditsInSubUnits={creditsInSubUnits} />
      </InfoContainer>
      {!(userTotalBalance?.amount <= 0) && isFeatureEnabled(FEATURE_SELL) && (
        <ConvertCredits
          handleOnClick={handleOnClick}
          creditsInSubUnits={creditsInSubUnits}
          inCreditBonusIncreaseGroup={inCreditBonusIncreaseGroup}
        />
      )}
      <CreditsCheckbox
        disabled={loading || money.toBaseUnit(maxCreditAmount).amount <= 0}
        {...hookFormFieldProps}
        label={t('credits.checkbox.labelWithNoCredits')}
        onChange={(params) => {
          onChange()
          formOnChange(params)
        }}
        userTotalBalance={userTotalBalance}
        maxCreditAmount={maxCreditAmount}
        creditsInSubUnits={creditsInSubUnits}
      />
    </Wrapper>
  )
}

const Balance = ({ userTotalBalance, creditsInSubUnits }) => {
  const { t } = useTranslation(['adyenSessionPayment', 'balance'])
  const theme = useTheme()
  if (userTotalBalance?.amount) {
    return (
      <InfoBlock>
        <P design='h4' noMargin style={{ color: theme.color.green.shade1 }}>
          {money.formatBaseUnit(userTotalBalance)}
        </P>
        <P design='body3' noMargin style={{ color: theme.color.green.shade1 }}>
          {t('creditsNudge.infoBox.salesBalanceSubtitle')}
        </P>
      </InfoBlock>
    )
  }
  if (creditsInSubUnits?.amount) {
    return (
      <InfoBlock>
        <P design='h4' noMargin style={{ color: theme.color.green.shade1 }}>
          {money.formatSubUnit(creditsInSubUnits)}
        </P>
        <P design='body3' noMargin style={{ color: theme.color.green.shade1 }}>
          {t('creditsNudge.infoBox.creditsSubtitle')}
        </P>
      </InfoBlock>
    )
  }
  return null
}

export default CreditsV2
