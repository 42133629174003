export const SIZES = {
  GIGANTIC: 'gigantic',
  EXTRA_HUGE: 'extraHuge',
  HUGE: 'huge',
  EXTRA_GRAND: 'extraGrand',
  GRAND: 'grand',
  EXTRA_LARGE: 'extraLarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  EXTRA_SMALL: 'extraSmall',
  MINI: 'mini'
}

export const WEIGHTS = {
  BOLD: 'bold',
  SEMIBOLD: 'semibold',
  MEDIUM: 'medium',
  REGULAR: 'regular',
  LIGHT: 'light'
}

export const blobColorSelector = {
  pendingSale: 'yellow',
  pendingReevaluation: 'blue',
  ongoingSale: 'blue',
  pendingPayment: 'orange',
  rejected: 'red',
  sold: 'green',
  bought: 'green',
  donated: 'grey',
  claim: 'red',
  sellerReturned: 'grey',
  awaitingApproval: 'grey',
  freeFreight: 'green',
  newCondition: 'blue',
  p2p: 'green',
  lastChance: 'red',
  fastDelivery: 'blue',
  businessItem: 'grey',
  timedCampaign: 'red'
}
