import React from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'
import styled from 'styled-components'
import { Tab } from '@sellpy/design-system-react-web'
import { getCurrentParseUser, useCurrentUserId } from '../../../../common/user/currentUser'
import { HierarchicalItemList } from '../../../search/components/filters/FilterComponents.jsx'
import { pathToEntity } from '../../../myItems/utils'

const selectedSearchListFromPath = ({ path, resource, id }) => ({
  selectedList: resource === 'list' && id ? id : null,
  isAllFavoritesPath: !id && path === 'favorites'
})

export const GET_FAVORITE_LISTS = gql`
  query getFavoriteLists($userId: ID!) {
    itemFavoriteLists(
      where: { user: { have: { objectId: { equalTo: $userId } } }, status: { equalTo: "enabled" } }
    ) {
      edges {
        node {
          objectId
          name
          public
          user {
            objectId
          }
        }
      }
    }
  }
`

export const useGetFavoriteAndRecommendedLists = ({ id, resource, path }) => {
  const { selectedList, isAllFavoritesPath } = selectedSearchListFromPath({ id, resource, path })
  const currentUser = useCurrentUserId()
  const { data: favoriteListsData, loading } = useQuery(GET_FAVORITE_LISTS, {
    variables: { userId: currentUser },
    skip: !currentUser
  })
  const favoriteListsFromGraphql = favoriteListsData?.itemFavoriteLists.edges?.map(
    ({ node }) => node
  )
  const { navigate } = useNavigate()
  const { t } = useTranslation('favorite')
  const renderFavoriteList = favoriteListsFromGraphql?.map((favoriteList) => ({
    link: `/my-shopping/favorites/list/${favoriteList.objectId}`,
    label: favoriteList.name,
    id: favoriteList.objectId,
    onClick: () => {
      navigate(`/my-shopping/favorites/list/${favoriteList.objectId}`)
    }
  }))

  const renderAllFavorites = currentUser && {
    link: '/my-shopping/favorites',
    label: t('allFavorites'),
    id: 'all',
    onClick: () => {
      navigate('/my-shopping/favorites')
    }
  }

  const selected = () => {
    if (isAllFavoritesPath) return 'all'
    return selectedList
  }

  return {
    allFavorites: renderAllFavorites,
    favoriteLists: renderFavoriteList,
    selected: selected(),
    loading
  }
}

const FavoritesListSelector = () => {
  const params = useParams()
  const { pathname } = useLocation()
  const { id, resource } = params
  const { path } = pathToEntity({ pathname })
  const { allFavorites, favoriteLists, selected } = useGetFavoriteAndRecommendedLists({
    id,
    resource,
    path
  })

  if (!getCurrentParseUser() || (!allFavorites && !favoriteLists)) return null
  return (
    <HierarchicalItemList scrollRef={null} showMore={true} showFade={favoriteLists?.length > 10}>
      {allFavorites && favoriteLists && (
        <TabsMenuDesktop tabs={[allFavorites, ...favoriteLists]} activeTab={selected} />
      )}
    </HierarchicalItemList>
  )
}

export default FavoritesListSelector

const TabWrapperDesktop = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: white;

  a {
    text-decoration: none;
  }
`

// This custom styling will be part of an upcoming update of the Tab component in the design system and can be removed eventually.
const StyledTabMenu = styled(Tab)`
  border-bottom: none;
  display: flex;
  border-left: ${({ theme, active }) =>
    active ? `2px solid ${theme.color.black.default}` : `1px solid ${theme.color.grey.shade8}`};
  :hover {
    span {
      color: ${({ theme }) => theme.color.black.default} !important;
    }
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
`

const TabsMenuDesktop = ({ tabs, activeTab }) => (
  <TabWrapperDesktop>
    {tabs.map((tab, index) => (
      <Link key={tab.id} to={tab.link}>
        <StyledTabMenu
          label={tab.label}
          active={(!activeTab && index === 0) || activeTab === tab.id}
        />
      </Link>
    ))}
  </TabWrapperDesktop>
)
