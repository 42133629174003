import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { Button, P } from '@sellpy/design-system-react-web'
import { ImageStack } from '../../../components/ui/ImageStack.jsx'
import { isCartFooterVisible, getSortedItemsInCart } from '../../../../common/cart/selectors'
import { formatBaseUnit } from '../../../../common/lib/moneyFormat'
import { useResponsive } from '../../../hooks'
import { totalPriceOfItemsInCart } from '../../../../common/cartCheckout/selectors'
import { useCurrentUserId } from '../../../../common/user/currentUser.js'
import { CHECKOUT_ROUTES } from '../../utils/constants.js'
import { ShortFreeFreightNudge } from './nudges/FreeFreightNudge.jsx'

const fadeIn = keyframes`
  0% { max-height: 0; }
  100% { max-height: 100px; }
`

const fadeOut = keyframes`
  0% { max-height: 100px; }
  100% { max-height: 0; }
`

const StyledCartLink = styled(Link)`
  text-decoration: none;
`

const ImageStackContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledImageStack = styled(ImageStack)`
  cursor: pointer;
`

const Base = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  overflow: hidden;
  animation: ${({ visible }) => (visible ? fadeIn : fadeOut)} 0.25s;
  animation-fill-mode: forwards;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  max-width: 55rem;
  padding: 1rem 1rem;
`
const InnerContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextContainer = styled.div`
  margin-left: '0.5rem';
`
const StyledFreeFreight = styled(ShortFreeFreightNudge)`
  margin-top: 0.5rem;
  text-align: right;
`

export const CartFooter = () => {
  const location = useLocation()
  const userId = useCurrentUserId()
  const visible = useSelector((state) => isCartFooterVisible(state, { location, userId }))

  const [shouldRender, setRender] = useState(visible)

  useEffect(() => {
    if (visible) setRender(true)
  }, [visible])

  const onAnimationEnd = () => {
    if (!visible) setRender(false)
  }

  return shouldRender ? (
    <Base visible={visible} onAnimationEnd={onAnimationEnd}>
      <FooterContent />
    </Base>
  ) : null
}

const FooterContent = () => {
  const userId = useCurrentUserId()
  const itemsInCart = useSelector((state) => getSortedItemsInCart(state, { userId }))
  const totalPrice = useSelector((state) => totalPriceOfItemsInCart(state, { userId }))
  const { t } = useTranslation('cart')
  const { isMobile, isTablet } = useResponsive()
  const images = itemsInCart.map((item) => item.get('images').first()).toJS()

  return (
    <ContentContainer>
      <InnerContentContainer>
        <ImageStackContainer>
          <CartLink>
            <StyledImageStack
              images={images}
              limit={isTablet ? 2 : 4}
              stackConfig={isTablet ? { overlap: 26, diameter: 40 } : undefined}
            />
          </CartLink>
          <TextContainer>
            <P design='body5'>{t('footer.itemsInCart', { count: itemsInCart.size })}</P>
            <P design='body6' noMargin>
              {t('footer.sum', {
                priceString: formatBaseUnit(totalPrice)
              })}
            </P>
          </TextContainer>
        </ImageStackContainer>
        <CartLink>
          <Button label={t('footer.toCart')} size={isMobile ? 'small' : 'medium'} />
        </CartLink>
      </InnerContentContainer>
      <StyledFreeFreight />
    </ContentContainer>
  )
}

const CartLink = ({ children, props, onClick }) => (
  <StyledCartLink
    reloadDocument={window.appUpdateAvailable}
    to={CHECKOUT_ROUTES.CART_ROUTE}
    onClick={onClick}
    {...props}
  >
    {children}
  </StyledCartLink>
)
