import styled from 'styled-components'
import { mediaQueries } from '../uiComponents/mediaQueries'

export const StoreGrid = styled.div`
  margin: 8px 0;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-row-gap: 8px;
  grid-template-areas:
    'bc bc bc bc bc bc bc bc bc bc bc bc'
    'hr hr hr hr hr hr hr hr hr hr hr hr'
    'id id id id id id id id id id id id'
    'rs rs rs rs rs rs rs rs rs rs rs rs'
    'pg pg pg pg pg pg pg pg pg pg pg pg';
  grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) auto minmax(0, auto);
  ${mediaQueries.largerThanTablet} {
    ${({ theme }) => theme.layout.contentPadding};
    margin: 16px 0;
    grid-column-gap: 16px;
    grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) minmax(0, auto) auto auto;
    grid-template-areas:
      'lc lc lc bc bc bc bc bc bc bc bc bc'
      'lc lc lc hr hr hr hr hr hr hr hr hr'
      'lc lc lc hf hf hf hf hf hf hf hf hf'
      'lc lc lc id id id id id id id id id'
      'lc lc lc rs rs rs rs rs rs rs rs rs'
      'lc lc lc pg pg pg pg pg pg pg pg pg';
  }
  ${mediaQueries.largeDesktop} {
    margin: 24px 0;
    grid-template-areas:
      'lc lc bc bc bc bc bc bc bc bc bc bc'
      'lc lc hr hr hr hr hr hr hr hr hr hr'
      'lc lc hf hf hf hf hf hf hf hf hf hf'
      'lc lc id id id id id id id id id id'
      'lc lc rs rs rs rs rs rs rs rs rs rs'
      'lc lc pg pg pg pg pg pg pg pg pg pg';
  }
  .item-tile-container {
    grid-column: span 6;
    ${mediaQueries.largerThanTablet} {
      grid-column: span 3;
    }
    ${mediaQueries.largeDesktop} {
      grid-column: span 2;
    }
  }
`

export const LeftColumnDiv = styled.div`
  grid-area: lc;
`

export const HeaderDiv = styled.div`
  grid-area: hr;
  margin-bottom: 16px;
  ${mediaQueries.tablet} {
    border-bottom: 1px solid ${({ theme }) => theme.color.grey.shade7};
    margin-bottom: 8px;
  }
`
