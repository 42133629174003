import { Map } from 'immutable'
import { money, promoCode as promoTools, marketplace } from '@sellpy/commons'
import { createSelector } from 'reselect'
import { getEntity } from './base'
import {
  ownFreshReservations,
  offersForOwnFreshReservations,
  sellerReturnFeesForOwnFreshReservations
} from './reservations'

const zero = { amount: 0, currency: marketplace.CURRENCY[process.env.REGION] }

export const getItemsInOrder = createSelector(
  [(state, orderId) => state.entities.Order.get(orderId), getEntity('Item')],
  (order, items) => {
    if (!order) return Map()
    const itemsInOrder = order.get('items').toSet()
    return items.filter((item) => itemsInOrder.has(item.get('objectId')))
  }
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const getItemsInCart = createSelector(
  [ownFreshReservations, getEntity('Item')],
  (reservations, items) => {
    const itemsInReservations = reservations
      .filter((reservation) => ['market', 'sellerReturn'].includes(reservation.get('type')))
      .map((reservation) => reservation.get('item'))
      .toSet()
    return items.filter((item) => itemsInReservations.has(item.get('objectId')))
  }
)

const priceForReservedItem = (itemId, offers, sellerReturnFees, reservations) => {
  const reservation = reservations.find((reservation) => reservation.get('item') === itemId)
  const offer = offers.find((offer) => offer.get('item') === itemId)
  if (!reservation) {
    return zero
  } else if (_isFreshSellerReturnReservation(reservation)) {
    return sellerReturnFees.get(reservation.get('objectId'))
  } else if (offer) {
    return offer.get('pricing')
      ? offer.get('pricing').toJS()
      : { amount: offer.get('value'), currency: 'SEK' }
  } else {
    return zero
  }
}

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const priceForReservedItemsSelector = createSelector(
  [
    (_, { items }) => items,
    offersForOwnFreshReservations,
    sellerReturnFeesForOwnFreshReservations,
    ownFreshReservations
  ],
  (items, offers, sellerReturnFees, reservations) =>
    items.map((item) =>
      priceForReservedItem(item.get('objectId'), offers, sellerReturnFees, reservations)
    )
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const totalPriceForItemsWithPromoCode = createSelector(
  [
    offersForOwnFreshReservations,
    sellerReturnFeesForOwnFreshReservations,
    ownFreshReservations,
    getItemsInCart,
    (_, params) => params
  ],
  (offers, sellerReturnFees, reservations, items, { promoCode }) => {
    const itemData = items.reduce((resultinObject, item) => {
      const itemId = item.get('objectId')
      const price = priceForReservedItem(itemId, offers, sellerReturnFees, reservations)
      resultinObject[itemId] = {
        price,
        brand: item.getIn(['metadata', 'brand']),
        featuredIn: item.get('featuredIn')
      }
      return resultinObject
    }, {})

    const reducedPrice = promoTools.applyPromoCode({
      promoCode,
      itemData
    })

    const prices = items.map((item) => {
      const id = item.get('objectId')

      return {
        originalPrice: money.toSubUnit(itemData[id].price),
        price: money.toSubUnit(reducedPrice.itemAmounts[id])
      }
    })

    return prices.toJS()
  }
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const totalPriceForReservedItemsSelector = createSelector(
  [
    (_, { items }) => items,
    offersForOwnFreshReservations,
    sellerReturnFeesForOwnFreshReservations,
    ownFreshReservations
  ],
  (items, offers, sellerReturnFees, reservations) => {
    if (items.isEmpty()) return zero
    const itemCosts = items.map((item) =>
      priceForReservedItem(item.get('objectId'), offers, sellerReturnFees, reservations)
    )
    return money.toSubUnit(money.add(...itemCosts.toArray()))
  }
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const pricesForItemsInCartSelector = createSelector(
  [
    getItemsInCart,
    offersForOwnFreshReservations,
    sellerReturnFeesForOwnFreshReservations,
    ownFreshReservations
  ],
  (items, offers, sellerReturnFees, reservations) =>
    items.map((item) =>
      priceForReservedItem(item.get('objectId'), offers, sellerReturnFees, reservations)
    )
)

const _isFreshSellerReturnReservation = (reservation) =>
  reservation.get('status') === 'fresh' && reservation.get('type') === 'sellerReturn'

/**
 * This selector requires the `userId` parameter as it indirectly uses the `ownFreshReservations` selector.
 */
export const getRegularItemsInCart = createSelector([getItemsInCart], (itemsInCart) =>
  itemsInCart.filter((item) => !item.get('p2p'))
)
