import React from 'react'
import { useTranslation } from 'react-i18next'
import { ICONS } from '@sellpy/design-system-react-web'
import { SuggestionItem, SuggestionList } from '../components/SuggestionItem.jsx'
import { SuggestionSection } from '../components/SuggestionSection.jsx'

export const Brands = ({ items, source, getListProps, getItemProps }) => {
  const { t } = useTranslation('search')

  return (
    <SuggestionSection title={t('suggestions.brands.header')}>
      <SuggestionList {...getListProps()}>
        {items.map((item) => (
          <SuggestionItem
            key={item.objectID}
            title={item.brand}
            icon={ICONS.MULTIPLE_TAGS}
            getItemProps={getItemProps}
            item={item}
            source={source}
          />
        ))}
      </SuggestionList>
    </SuggestionSection>
  )
}
