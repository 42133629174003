import Parse from 'parse'
import { cacheEntities } from '../../common/entities/actions'
import { getCurrentParseUser } from '../../common/user/currentUser'

export const getCreditTransactionForPayOut = ({ payOut }) => async (dispatch) => {
  const user = getCurrentParseUser()
  const previousCreditPayout = await new Parse.Query('CreditTransaction')
    .equalTo('user', user)
    .equalTo('type', 'SALE_PAYOUT')
    .equalTo('payout', payOut)
    .first()
  dispatch(cacheEntities(previousCreditPayout))
}
