import { createSelector } from 'reselect'
import { getEntity } from '../entities/selectors/base'

export const notificationSelector = createSelector(
  [getEntity('Notification'), (_, params) => params],
  (notifications, { userId }) => {
    return notifications
      .filter((notification) => notification.get('active'))
      .filter((notification) => notification.get('user') === userId)
  }
)
