import { createSelector } from 'reselect'
import { payout } from '@sellpy/commons'
import { getEntity } from '../entities/selectors/base'
const { PAYOUT_STATUS } = payout

export const totalBalanceSelector = (state) => state.balance.totalBalance
export const totalBalanceSelectorWithoutReservedAdFees = (state) =>
  state.balance.totalBalanceWithoutReservedAdFees

export const ownPayOutSelector = createSelector(
  [getEntity('PayOut'), (_, params) => params],
  (payouts, { payoutId, userId }) =>
    payouts
      .filter((payout) => payout.get('objectId') === payoutId)
      .filter((payout) => payout.get('user') === userId)
      .first()
)

export const ownPayOutAwaitingVerificationSelector = createSelector(
  [getEntity('PayOut'), (_, userId) => userId],
  (payouts, userId) =>
    payouts
      .filter((payout) => payout.get('user') === userId)
      .filter((payout) => payout.get('status') === PAYOUT_STATUS.AWAITING_VERIFICATION)
      .first()
)

export const balanceDetailsSelector = createSelector(
  [(state) => state.balance.balanceDetails],
  (events) => {
    return events.flatten(1)
  }
)

export const currentBalancePageSelector = createSelector(
  [(state) => state.balance.balanceDetails],
  (events) => {
    return events.size
  }
)

export const allBalanceDataFetchedSelector = createSelector(
  [(state) => state.balance.balanceDetails],
  (events) => {
    return events.get(events.size - 1)?.size === 0
  }
)
