import { Map, Record } from 'immutable'
import * as actions from './actions'

const InitialState = Record({
  publicOrders: Map()
})
const initialState = new InitialState()

export default function orderReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState

  switch (action.type) {
    case actions.GET_PUBLIC_ORDER_DETAILS_FOR_ITEM: {
      return state.mergeIn(['publicOrders'], action.publicOrder)
    }
  }
  return state
}
