import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { user as userTools, region as regionFunctions } from '@sellpy/commons'
import styled from 'styled-components'
import { A, RouterLink, Span } from '@sellpy/design-system-react-web'
import { isFeatureEnabled, FEATURE_SELL } from '../../../featureToggle'
import { logout } from '../../../../common/user/actions'
import { useResponsive } from '../../../hooks'
import { showCancellationPolicy, company, contactAddress } from '../../../../common/region/config'
import constants from '../../../region/constants.json'
import { useCountry } from '../../../../common/hooks/useCountry'
import { mediaQueries } from '../../../uiComponents/mediaQueries'
import { useCurrentParseUser } from '../../../../common/user/currentUser'

const ImpersonationWarning = styled.h3`
  background-color: ${({ theme }) => theme.color.red.shade6};
  color: white;
`

const PolicyLink = styled.div`
  margin: 0 4px;
`
const BottomLinks = styled.div`
  color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? 'white' : theme.color.black.default};
  display: flex;
  flex-wrap: wrap;
  ${mediaQueries.tablet} {
    justify-content: flex-start;
  }
`

const BottomWrapper = styled.div`
  background-color: ${({ customerImpersonation, theme }) =>
    customerImpersonation && theme.color.red.shade6};
  ${mediaQueries.tablet} {
    padding: 0px 16px 0px;
  }
`
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${mediaQueries.tablet} {
    display: flex;
    text-align: left;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    padding: 0;
  }
`

const BottomInfo = () => {
  const customerImpersonation = useSelector((state) => state.entities.CustomerImpersonation.size)
  const user = useCurrentParseUser()
  const dispatch = useDispatch()
  const { isTablet } = useResponsive()
  const { t } = useTranslation(['general', 'footer'])
  return (
    <BottomWrapper customerImpersonation={customerImpersonation}>
      {customerImpersonation ? (
        <ImpersonationWarning>
          {t('general:customerImpersonation.banner').toUpperCase()}
        </ImpersonationWarning>
      ) : null}
      <BottomContainer noPadding={!isTablet}>
        <BottomLinks
          customerImpersonation={customerImpersonation}
          style={{ gridColumn: 'span 3', alignItems: 'center' }}
        >
          <Location customerImpersonation={customerImpersonation} />
          <PolicyLink>
            {userTools.userIsFull(user) || customerImpersonation ? (
              <BottomInfoTextLink
                noMargin
                customerImpersonation={customerImpersonation}
                onClick={() => dispatch(logout('/'))}
                to={'/'}
              >
                {t('footer:logout')}
              </BottomInfoTextLink>
            ) : (
              <BottomInfoTextLink
                customerImpersonation={customerImpersonation}
                noMargin
                to={'/login'}
              >
                {t('footer:login')}
              </BottomInfoTextLink>
            )}
          </PolicyLink>
          <Span noMargin design='body3'>
            {' '}
            {userTools.userIsFull(user) ? `| ${user.get('email')}` : ''}
          </Span>
        </BottomLinks>
        <BottomLinks style={{ gridColumn: 'span 4' }} customerImpersonation={customerImpersonation}>
          <PolicyLink>
            <Span noMargin design='body3'>
              © {company}{' '}
            </Span>
            |
          </PolicyLink>
          <Span noMargin design='body3'>
            {contactAddress}
          </Span>
        </BottomLinks>
        <BottomLinks style={{ gridColumn: 'span 5' }} customerImpersonation={customerImpersonation}>
          {isFeatureEnabled(FEATURE_SELL) && (
            <PolicyLink>
              <InfoText
                link={regionFunctions.sellingPolicyLink(process.env.REGION)}
                label={t('footer:sellTerms')}
              />
              <span> | </span>
            </PolicyLink>
          )}
          <PolicyLink>
            <InfoText
              link={regionFunctions.purchasePolicyLink(process.env.REGION)}
              label={t('footer:purchaseTerms')}
            />
            <span> | </span>
          </PolicyLink>
          <PolicyLink>
            <InfoText
              link={regionFunctions.dataPolicyLink(process.env.REGION)}
              label={t('footer:dataPolicy')}
            />
          </PolicyLink>
          <span> | </span>
          <PolicyLink>
            <InfoText
              link={regionFunctions.cookiePolicyLink(process.env.REGION)}
              label={t('footer:cookiePolicy')}
            />
          </PolicyLink>
          {showCancellationPolicy && (
            <>
              <PolicyLink>
                <InfoText
                  link={regionFunctions.cancellationPolicyLink(process.env.REGION)}
                  label={t('footer:cancellationPolicy')}
                />
              </PolicyLink>
              <PolicyLink>
                <span> | </span>
                <InfoText
                  link={regionFunctions.imprintLink(process.env.REGION)}
                  label={t('footer:imprint')}
                />
              </PolicyLink>
            </>
          )}
        </BottomLinks>
      </BottomContainer>
    </BottomWrapper>
  )
}

const BottomInfoTextA = styled(({ customerImpersonation, ...props }) => (
  <A design='body3' {...props} />
))`
  color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? 'white' : theme.color.black.default};
`

const BottomInfoTextLink = styled(({ customerImpersonation, ...props }) => (
  <RouterLink design='body3' {...props} />
))`
  color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? 'white' : theme.color.black.default};
`

const InfoText = ({ link, label }) => {
  const customerImpersonation = useSelector((state) => state.entities.CustomerImpersonation.size)
  return (
    <BottomInfoTextA
      noMargin
      customerImpersonation={customerImpersonation}
      href={link}
      target='__blank'
      rel='noopener noreferrer'
    >
      {label}
    </BottomInfoTextA>
  )
}

const Location = () => {
  const country = useCountry()
  const location = constants.country[country] || constants.country[process.env.REGION]
  if (!location) return null
  return (
    <Span noMargin design='body3'>
      {location} |
    </Span>
  )
}

export default BottomInfo
