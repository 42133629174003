import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Spinner, H1 } from '@sellpy/design-system-react-web'
import { loginPasswordless } from '../../common/user/actions'
import { useQuery } from '../hooks'
import LoadingDots from '../components/LoadingDots.jsx'
import { usePasswordlessLoginError } from './passwordlessLoginError.js'

const Wrapper = styled.div`
  text-align: center;
  margin: 16px 0;
`

export const PasswordlessHook = ({ children }) => {
  const { t } = useTranslation('common')
  const { authToken } = useQuery()
  const dispatch = useDispatch()
  const loginError = usePasswordlessLoginError()?._error

  useEffect(() => {
    authToken && dispatch(loginPasswordless(authToken))
  }, [authToken, dispatch])
  const windowPaymentDataExists = Boolean(window.paymentData)
  const searchParamsFromUrl = new URLSearchParams(window.location.search)

  /**
   * In redirectAfterLogin() function in app-payments/common/user/actions.js
   * The router from sellpy.xx is used to remove the authToken from the URL.
   * Since app-payments has its own router, the location hook will not trigger when
   * query params are updated. Therefore we need to check if the authToken is in the window-object instead of
   * using the router.
   *
   * This is a temporary solution until we have a better solution.
   */

  const checkAuthToken = windowPaymentDataExists ? searchParamsFromUrl.has('authToken') : authToken
  return checkAuthToken ? (
    <Wrapper>
      {loginError ? (
        <H1 design='h3'>{loginError}</H1>
      ) : (
        <>
          <H1 design='body1'>
            {t('login.passwordless.loggingIn')}
            <LoadingDots size='small' />
          </H1>
          <Spinner />
        </>
      )}
    </Wrapper>
  ) : (
    children
  )
}
