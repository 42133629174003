import { analyticsShowLoggedOutFavoriteModal } from '../analytics/actions'

export const SHOW_EDIT_FAVORITE_MODAL = 'SHOW_EDIT_FAVORITE_MODAL'
export const HIDE_EDIT_FAVORITE_MODAL = 'HIDE_EDIT_FAVORITE_MODAL'
export const showEditFavoriteModal = (itemId) => ({
  type: SHOW_EDIT_FAVORITE_MODAL,
  itemId
})

export const hideEditFavoriteModal = () => ({ type: HIDE_EDIT_FAVORITE_MODAL })

export const SHOW_LOGGED_OUT_FAVORITE_MODAL = 'SHOW_LOGGED_OUT_FAVORITE_MODAL'
export const HAS_SHOWN_LOGGED_OUT_FAVORITE_MODAL = 'HAS_SHOWN_LOGGED_OUT_FAVORITE_MODAL'

export const showLoggedOutFavoriteModal = () => {
  analyticsShowLoggedOutFavoriteModal()
  window.sessionStorage.setItem(HAS_SHOWN_LOGGED_OUT_FAVORITE_MODAL, true)
  return {
    type: SHOW_LOGGED_OUT_FAVORITE_MODAL
  }
}

export const HIDE_LOGGED_OUT_FAVORITE_MODAL = 'HIDE_LOGGED_OUT_FAVORITE_MODAL'
export const hideLoggedOutFavoriteModal = () => ({
  type: HIDE_LOGGED_OUT_FAVORITE_MODAL
})
