import { createSelector } from 'reselect'
import { getItemsInCart, pricesForItemsInCartSelector } from '../entities/selectors/checkout'
import { ownFreshReservations } from '../entities/selectors/reservations'

const hideCartFooterOnRoutes = ['/advance-payment', '/payment', '/checkout']

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const isCartFooterVisible = createSelector(
  [(_, params) => params, getItemsInCart, pricesForItemsInCartSelector],
  ({ location }, items, pricesForItems) =>
    location &&
    !hideCartFooterOnRoutes.some((route) => location.pathname.includes(route)) &&
    Boolean(items.size) &&
    Boolean(pricesForItems)
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const getSortedItemsInCart = createSelector(
  [getItemsInCart, ownFreshReservations],
  (itemsInCart, freshReservations) => {
    const reservations = freshReservations.filter((reservation) =>
      ['market', 'sellerReturn'].includes(reservation.get('type'))
    )
    return itemsInCart
      .sortBy((item) => {
        const reservation = reservations.find(
          (reservation) => reservation.get('item') === item.get('objectId')
        )
        return reservation ? reservation.get('createdAt') : new Date(1970).toISOString()
      })
      .reverse()
      .toList()
  }
)
