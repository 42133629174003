import { createSelector } from 'reselect'
import { getEntity } from './base'
// Only to be used in actions and other selectors in components use: reservationsForItemsInCartSelector
export const ownFreshReservations = createSelector(
  [getEntity('ItemReservation'), (_, params) => params],
  (reservations, { userId }) =>
    reservations
      .filter((reservation) => reservation.get('status') === 'fresh')
      .filter((reservation) => reservation.get('user') === userId)
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const ownFreshReservationForItem = createSelector(
  [ownFreshReservations, (_, params) => params],
  (reservations, { itemId }) =>
    reservations.find((reservation) => reservation.get('item') === itemId)
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const offersForOwnFreshReservations = createSelector(
  [getEntity('MarketOffer'), ownFreshReservations],
  (marketOffers, reservations) => {
    if (marketOffers.size === 0) return []
    return reservations
      .filter((reservation) => reservation.get('type') === 'market')
      .mapEntries(([_, reservation]) => {
        const offer = marketOffers.get(reservation.get('marketOffer'))
        return [offer.get('objectId'), offer]
      })
  }
)

/**
 * This selector requires the `userId` parameter as it uses the `ownFreshReservations` selector.
 */
export const sellerReturnFeesForOwnFreshReservations = createSelector(
  [
    ownFreshReservations,
    getEntity('Item'),
    getEntity('Bag'),
    (state) => state.cart.get('pricingModels')
  ],
  (reservations, items, bags, pricingModels) => {
    return reservations
      .filter((reservation) => reservation.get('type') === 'sellerReturn')
      .mapEntries(([_, reservation]) => {
        const item = items.get(reservation.get('item'))
        const bag = bags.get(item.get('bag'))
        const pricingModel = pricingModels[bag.get('currency')][bag.get('pricingModel')]
        return [
          reservation.get('objectId'),
          {
            amount: pricingModel.adFee,
            currency: bag.get('currency')
          }
        ]
      })
  }
)
