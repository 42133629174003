import { useCurrentParseUser } from '../user/currentUser'
import { region, validShippingCountries } from '../region/config'
import { useInferredLocation } from './useInferredLocation'
/**
 * Returns the country of the current user.
 * Either from the active region, the logged in user profile or the inferred IP location.
 * By default the countries are limited to validShippingCountries of the active region.
 */
export const useCountry = ({ allowCountryOutsideRegion = false } = {}) => {
  const inferredLocation = useInferredLocation()
  const userCountry = useCurrentParseUser()?.get('address')?.country
  const activeRegion = region()

  if (validShippingCountries.length === 1) return validShippingCountries[0]
  if (validShippingCountries.includes(userCountry)) return userCountry
  if (validShippingCountries.includes(inferredLocation)) return inferredLocation
  if (validShippingCountries.includes(activeRegion)) return activeRegion
  if (allowCountryOutsideRegion) return userCountry ?? inferredLocation ?? null
  return null
}
