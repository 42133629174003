import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLatestSearches } from '../../../../../../../common/localStorage.js'
import useResponsive from '../../../../../../hooks/useResponsive.js'
import { SuggestionChipItem, SuggestionChipList } from '../components/SuggestionChip.jsx'
import { SuggestionSection } from '../components/SuggestionSection.jsx'

export const BrandSuggestions = ({ items, source, getListProps, getItemProps, hasQuery }) => {
  const { t } = useTranslation('search')
  const { isTablet } = useResponsive()

  const hasLatestSearches = getLatestSearches().length > 0

  const isSlider = isTablet && (hasQuery || hasLatestSearches)

  return (
    <SuggestionSection
      title={!isSlider ? t(`suggestions.brandSuggestions.header`) : undefined}
      isChipList={!isSlider}
    >
      <SuggestionChipList isSlider={isSlider} {...getListProps()}>
        {items.map((item) => (
          <SuggestionChipItem
            key={item.label}
            getItemProps={getItemProps}
            title={item.label}
            item={item}
            source={source}
          />
        ))}
      </SuggestionChipList>
    </SuggestionSection>
  )
}
